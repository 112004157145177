import React, { useState, useEffect } from 'react';
import {
  Modal,
  InputGroup,
  FormControl,
  Button,
  Spinner,
  FormText,
  FormCheck,
  DropdownButton,
  Dropdown,
  Alert
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  generateQuestionsFromLink,
  generateQuestionsFromText
} from '../../../../../store/questionGenerationSlice'; // Предположим, что у вас есть генерация по тексту
import PropTypes from 'prop-types';
import Web3 from 'web3';
const ERC20Abi = require('./USDT_abi.json');
const contractAbi = require('./Contract_abi.json'); // Замените на ваш ABI

const GenerateQuestionsModal = ({
  showModal,
  handleClose,
  questionSet,
  onQuestionsGenerated
}) => {
  const [inputType, setInputType] = useState('url'); // Управление типом ввода
  const [inputValue, setInputValue] = useState(''); // Управление значением ввода
  const [isValidUrl, setIsValidUrl] = useState(true); // Состояние для валидации URL
  const [isLoading, setIsLoading] = useState(false);
  const [hasApproval, setHasApproval] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [insufficientFunds, setInsufficientFunds] = useState(false); // Состояние для проверки средств
  const [isConnected, setIsConnected] = useState(false); // Состояние для проверки подключения аккаунта
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(false); // Состояние для проверки правильной сети
  const [isAgreed, setIsAgreed] = useState(false); // Состояние для чекбокса соглашения
  const [selectedBlockchain, setSelectedBlockchain] = useState('Gravity'); // Состояние для выбора блокчейна
  const [selectedLanguage, setSelectedLanguage] = useState('English'); // Состояние для выбора языка

  const [usdtAddress, setUsdtAddress] = useState('');
  const [contractAddress, setContractAddress] = useState('');
  const [priceInUSDT, setPriceInUSDT] = useState('1000000'); // Значение по умолчанию для BSC

  const dispatch = useDispatch();
  const userId = useSelector(state => state.auth.user?.id); // Обновлено для обработки несуществующего пользователя

  // Web3 и контракты
  const web3 = new Web3(window.ethereum);
  const usdtContract = new web3.eth.Contract(ERC20Abi, usdtAddress);
  const contract = new web3.eth.Contract(contractAbi, contractAddress); // Создаем экземпляр смарт-контракта

  // Регулярное выражение для валидации URL
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // Протокол
      '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // Доменное имя
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // ИЛИ IP (v4) адрес
      '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // Порт и путь
      '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // Строка запроса
      '(\\#[-a-zA-Z\\d_]*)?$',
    'i'
  );

  const handleInputChange = e => {
    const value = e.target.value;
    setInputValue(value);

    if (inputType === 'url') {
      setIsValidUrl(urlPattern.test(value)); // Валидация URL
    }
  };

  // Обновление адресов контрактов и суммы платежа в зависимости от выбранного блокчейна
  useEffect(() => {
    if (selectedBlockchain === 'Gravity') {
      setUsdtAddress('0x816E810f9F787d669FB71932DeabF6c83781Cd48'); // Адрес для Gravity
      setContractAddress('0x535aE3FC35C7ca8996020A39693868d8c47953ae'); // Адрес контракта для Gravity
      setPriceInUSDT('1000000'); // 1 USDT для Gravity
    } else if (selectedBlockchain === 'BSC') {
      setUsdtAddress('0x55d398326f99059fF775485246999027B3197955'); // Адрес контракта USDT на BSC
      setContractAddress('0xB6c8083f8600fC2aBf8bb831Ea9A4F7b5ca972DA'); // Ваш контракт на BSC
      setPriceInUSDT('1000000000000000000'); // 1 USDT для BSC (в единицах WEI)
    }
  }, [selectedBlockchain]);

  // Проверка подключения аккаунта и сети
  useEffect(() => {
    const checkAccountConnection = async () => {
      try {
        const accounts = await web3.eth.getAccounts();
        if (accounts.length === 0) {
          setIsConnected(false);
        } else {
          setIsConnected(true);
        }

        const networkId = await web3.eth.net.getId();
        if (
          (selectedBlockchain === 'BSC' && parseInt(networkId) === 56) ||
          (selectedBlockchain === 'Gravity' && parseInt(networkId) === 1625)
        ) {
          setIsCorrectNetwork(true);
        } else {
          setIsCorrectNetwork(false);
        }
      } catch (error) {
        console.error('Error checking account connection and network:', error);
        setIsConnected(false);
        setIsCorrectNetwork(false);
      }
    };

    if (showModal) {
      checkAccountConnection();
    }
  }, [showModal, selectedBlockchain]);

  const handleApproveAndPay = async () => {
    setIsPaying(true);
    const accounts = await web3.eth.getAccounts();
    try {
      if (!hasApproval) {
        console.log('Approving USDT...');
        // Выполнить approve
        await usdtContract.methods.approve(contractAddress, priceInUSDT).send({
          from: accounts[0],
          gas: await usdtContract.methods
            .approve(contractAddress, priceInUSDT)
            .estimateGas({ from: accounts[0] }),
          gasPrice: await web3.eth.getGasPrice()
        });
        console.log('USDT approved');
        setHasApproval(true);
      }

      // Проверка ETH баланса
      const balance = await web3.eth.getBalance(accounts[0]);
      const gasEstimate = await usdtContract.methods
        .approve(contractAddress, priceInUSDT)
        .estimateGas({ from: accounts[0] });
      const gasPrice = await web3.eth.getGasPrice();
      const requiredEth = gasEstimate * gasPrice;
      setInsufficientFunds(parseInt(balance) < requiredEth);

      console.log('Sending payment...');
      await contract.methods.purchaseQuestionSet().send({
        from: accounts[0],
        gas: await contract.methods
          .purchaseQuestionSet()
          .estimateGas({ from: accounts[0] }),
        gasPrice: await web3.eth.getGasPrice()
      });
      console.log('Payment sent');

      handleGenerate(); // Запуск генерации вопросов после успешной оплаты
    } catch (error) {
      console.error('Error during approve and payment:', error);
      setIsPaying(false);
    }
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    try {
      const action =
        inputType === 'url'
          ? generateQuestionsFromLink({
              url: inputValue,
              questionSetId: questionSet,
              userId,
              language: selectedLanguage.toLowerCase() // Передаем выбранный язык
            })
          : generateQuestionsFromText({
              text: inputValue,
              questionSetId: questionSet,
              userId,
              language: selectedLanguage.toLowerCase() // Передаем выбранный язык
            });

      await dispatch(action);
      setIsLoading(false);
      handleClose();
      onQuestionsGenerated(); // Перезагрузить вопросы после успешной генерации
    } catch (error) {
      setIsLoading(false);
      console.error('Error generating questions:', error);
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Generate 10 Questions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Меню выбора блокчейна */}
        <InputGroup className="mb-3">
          <DropdownButton
            variant="outline-secondary"
            title={selectedBlockchain}
            id="blockchain-dropdown"
            onSelect={eventKey => setSelectedBlockchain(eventKey)}
          >
            <Dropdown.Item eventKey="Gravity">Gravity</Dropdown.Item>
            <Dropdown.Item eventKey="BSC">BSC</Dropdown.Item>
          </DropdownButton>
        </InputGroup>

        {!isCorrectNetwork && (
          <Alert variant="warning">
            Please switch to the correct network for the selected blockchain.
          </Alert>
        )}

        <form>
          {/* Выбор языка */}
          <InputGroup className="mb-3">
            <DropdownButton
              variant="outline-secondary"
              title={selectedLanguage}
              id="language-dropdown"
              onSelect={eventKey => setSelectedLanguage(eventKey)}
            >
              <Dropdown.Item eventKey="English">English</Dropdown.Item>
              <Dropdown.Item eventKey="Perisan">Perisan</Dropdown.Item>
              <Dropdown.Item eventKey="Chinese">Chinese</Dropdown.Item>
              <Dropdown.Item eventKey="Vietnamese">Vietnamese</Dropdown.Item>
              <Dropdown.Item eventKey="Japanese">Japanese</Dropdown.Item>
              <Dropdown.Item eventKey="Thai">Thai</Dropdown.Item>
              <Dropdown.Item eventKey="Tagalog">Tagalog</Dropdown.Item>
              <Dropdown.Item eventKey="Hindi">Hindi</Dropdown.Item>
              <Dropdown.Item eventKey="Korean">Korean</Dropdown.Item>
              <Dropdown.Item eventKey="Turkish">Turkish</Dropdown.Item>
              <Dropdown.Item eventKey="Nigerian">Nigerian</Dropdown.Item>
              <Dropdown.Item eventKey="Polish">Polish</Dropdown.Item>
              <Dropdown.Item eventKey="Pakistan">Pakistan</Dropdown.Item>
              <Dropdown.Item eventKey="Bangladesh">Bangladesh</Dropdown.Item>
              <Dropdown.Item eventKey="French">French</Dropdown.Item>
              <Dropdown.Item eventKey="Spanish">Spanish</Dropdown.Item>
              <Dropdown.Item eventKey="Russian">Russian</Dropdown.Item>
            </DropdownButton>
          </InputGroup>

          {/* Выбор типа ввода */}
          <InputGroup className="mb-3">
            <DropdownButton
              variant="outline-secondary"
              title={inputType === 'url' ? 'URL' : 'Text'}
              id="input-type-dropdown"
            >
              <Dropdown.Item onClick={() => setInputType('url')}>
                URL
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setInputType('text')}>
                Text
              </Dropdown.Item>
            </DropdownButton>
            {inputType === 'url' ? (
              <FormControl
                placeholder="Enter URL"
                aria-label="URL"
                value={inputValue}
                onChange={handleInputChange}
                isInvalid={!isValidUrl} // Подсветить, если URL недействителен
              />
            ) : (
              <FormControl
                as="textarea"
                placeholder="Enter Text"
                aria-label="Text"
                value={inputValue}
                onChange={handleInputChange}
                rows={6} // Задать высоту текстового поля
              />
            )}
            {!isValidUrl && inputType === 'url' && (
              <FormText className="text-danger">
                Please enter a valid URL.
              </FormText>
            )}
          </InputGroup>

          {/* Информация об оплате */}
          <FormText className="mb-3">
            Generating 10 questions using GPT-4 costs 1 USDT.
          </FormText>

          {/* Чекбокс для соглашения с условиями */}
          <FormCheck
            type="checkbox"
            id="termsCheckbox"
            label={
              <>
                I agree to the site&apos;s{' '}
                <a href="/terms" target="_blank" rel="noopener noreferrer">
                  terms and conditions
                </a>
                .
              </>
            }
            checked={isAgreed}
            onChange={e => setIsAgreed(e.target.checked)}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleClose}
          disabled={isPaying || isLoading}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleApproveAndPay}
          disabled={
            isLoading ||
            !inputValue ||
            (inputType === 'url' && !isValidUrl) ||
            isPaying ||
            insufficientFunds ||
            !isConnected ||
            !isCorrectNetwork ||
            !isAgreed
          } // Отключить, если средства недостаточны, аккаунт не подключен, сеть не та или не согласован
        >
          {isLoading ? (
            <>
              <Spinner animation="border" size="sm" className="me-2" />
              Generating questions...
            </>
          ) : hasApproval ? (
            'Pay and Generate 10 Questions'
          ) : (
            'Approve and Pay'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

GenerateQuestionsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  questionSet: PropTypes.string.isRequired,
  onQuestionsGenerated: PropTypes.func.isRequired
};

export default GenerateQuestionsModal;
