import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const Rules = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="rules" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Community Guidelines and Conduct</h5>
      </Card.Header>
      <Card.Body>
        <h6>General Guidelines</h6>
        <p className="fs-0 mb-0 fw-semi-bold">
          As a member of EzQuize, you agree to:
        </p>
        <ol type="1">
          <li>
            You must be an admin on the Discord server for which you are
            creating a quiz.
          </li>
          <li>The EzQuize bot must be invited to your Discord server.</li>

          <li>You must comply with all applicable laws and regulations.</li>
          <li>
            Adhere to all applicable laws, including data protection and privacy
            laws;
          </li>
          <li>
            Violation of these rules may result in account suspension or
            termination.
          </li>
        </ol>
        <hr className="my-4" />
        <h6>Responsibilities</h6>
        <p className="fs-0 mb-0 fw-semi-bold">You are responsible for:</p>
        <ol type="1">
          <li>
            Providing accurate information during registration and maintaining
            the security of your account;
          </li>
          <li>Any content you post or share on EzQuize;</li>
          <li>
            Obtaining necessary permissions for any third-party content you use
            or share;
          </li>
          <li>Keeping your contact information up to date;</li>
          <li>
            Reporting any abuse or violations of these guidelines to EzQuize.
          </li>
        </ol>
        <hr className="my-4" />
        <h6>Reporting Misconduct</h6>
        <p className="mb-0 ps-3">
          If you encounter any violations of these guidelines, please{' '}
          <a href="mailto:support@EzQuize.com">contact us</a> immediately. We
          take all reports seriously and will take appropriate action.
        </p>
        <hr className="my-4" />
        <h6>Third-Party Services</h6>
        <ol type="1" className="mb-0">
          <li>
            EzQuize may integrate with third-party services for enhanced
            functionality. We are not responsible for the content or policies of
            these third-party services.
          </li>
          <li>
            You are responsible for reading and understanding the terms and
            privacy policies of any third-party services you use.
          </li>
        </ol>
      </Card.Body>
    </Card>
  );
});

export default Rules;
