import React, { useEffect, useState } from 'react';
import { Nav, Button } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { handleDiscordCallback } from '../../../store/authSlice';
import { useDispatch, useSelector } from 'react-redux';

import ProfileDropdown from './ProfileDropdown';

const LandingRightSideNavItem = () => {
  const [discordUrl, setDiscordUrl] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [hasHandledCallback, setHasHandledCallback] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false); // State to manage dropdown visibility

  const toggleDropdown = () => {
    setShowDropdown(true);
  };

  useEffect(() => {
    const discordAuthUrl = `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FRONTEND_URL}&response_type=code&scope=identify%20guilds%20email%20role_connections.write`;
    setDiscordUrl(discordAuthUrl);

    if (searchParams.has('code') && !hasHandledCallback) {
      dispatch(handleDiscordCallback(searchParams.get('code'))).then(() => {
        setHasHandledCallback(true);
        navigate('/');
      });
    }
  }, [navigate, searchParams, dispatch]);

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  return (
    <>
      <Nav navbar className="ms-auto">
        <Nav.Item>
          {isAuthenticated ? (
            <ProfileDropdown show={showDropdown} onClick={toggleDropdown} /> // Pass showDropdown state to control visibility
          ) : (
            <Button
              onClick={() => (window.location.href = discordUrl)}
              className="btn btn-primary"
            >
              Login with Discord
            </Button>
          )}
        </Nav.Item>
      </Nav>
    </>
  );
};

export default LandingRightSideNavItem;
