import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Асинхронный thunk для генерации вопросов по ссылке
export const generateQuestionsFromLink = createAsyncThunk(
  'questions/generateQuestionsFromLink',
  async ({ url, questionSetId, userId, language }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/single-question/generate-from-link`,
        {
          url,
          questionSetId,
          userId,
          language // Передача параметра языка на сервер
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Асинхронный thunk для генерации вопросов на основе текста
export const generateQuestionsFromText = createAsyncThunk(
  'questions/generateQuestionsFromText',
  async ({ text, questionSetId, userId, language }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/single-question/generate-from-text`,
        {
          text,
          questionSetId,
          userId,
          language // Передача параметра языка на сервер
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const questionGenerationSlice = createSlice({
  name: 'questionGeneration',
  initialState: {
    isLoading: false,
    questions: [],
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(generateQuestionsFromLink.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(generateQuestionsFromLink.fulfilled, (state, action) => {
        state.isLoading = false;
        state.questions = action.payload;
      })
      .addCase(generateQuestionsFromLink.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      })
      .addCase(generateQuestionsFromText.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(generateQuestionsFromText.fulfilled, (state, action) => {
        state.isLoading = false;
        state.questions = action.payload;
      })
      .addCase(generateQuestionsFromText.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      });
  }
});

export default questionGenerationSlice.reducer;
