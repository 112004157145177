import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to fetch a single quiz by its ID
export const fetchQuizById = createAsyncThunk(
  'quiz/fetchQuizById',
  async ({ token, quizId }, thunkAPI) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/quizzes/details/${quizId}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Async thunk to edit a quiz by its ID
export const editQuiz = createAsyncThunk(
  'quiz/editQuiz',
  async ({ token, quizId, updatedData }, thunkAPI) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/quizzes/edit/${quizId}`,
        updatedData
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Async thunk to add a new quiz
export const addQuiz = createAsyncThunk(
  'quiz/addQuiz',
  async ({ token, quizData }, thunkAPI) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/quizzes/create`,
        quizData
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchUserAttemptsForQuiz = createAsyncThunk(
  'quiz/fetchUserAttemptsForQuiz',
  async ({ token, userId, quizId }, thunkAPI) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/quizzes/${quizId}/attempts/${userId}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
  attemptsLimit: '',
  attemptsNumberPerUser: '',
  commandName: '',
  createdAt: '',
  createdBy: '',
  description: '',
  endDate: '',
  name: '',
  numberOfCorrectAnswers: '',
  questionSet: '',
  questionsNumberPerAttempt: '',
  roleName: '',
  accessRole: '',
  serverId: '',
  startDate: '',
  status: '',
  timePerQuestion: '',
  timeout: '',
  updatedAt: '',
  usersLimit: '',
  userAttempts: [],
  isLoading: false,
  error: null
};

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    resetQuizData: state => {
      // Reset the quiz data to its initial state
      Object.assign(state, initialState);
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchQuizById.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchQuizById.fulfilled, (state, action) => {
      // Update the state with the fetched quiz data
      Object.assign(state, action.payload);
      state.isLoading = false;
    });
    builder.addCase(fetchQuizById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    });
    builder.addCase(editQuiz.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(editQuiz.fulfilled, (state, action) => {
      // Update the state with the edited quiz data
      Object.assign(state, action.payload);
      state.isLoading = false;
    });
    builder.addCase(editQuiz.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    });
    builder.addCase(addQuiz.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(addQuiz.fulfilled, (state, action) => {
      // Update the state with the added quiz data
      Object.assign(state, action.payload);
      state.isLoading = false;
    });
    builder.addCase(addQuiz.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    });
    builder.addCase(fetchUserAttemptsForQuiz.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchUserAttemptsForQuiz.fulfilled, (state, action) => {
      state.userAttempts = action.payload; // Update the state with the fetched user attempts
      state.isLoading = false;
    });
    builder.addCase(fetchUserAttemptsForQuiz.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    });
  }
});

export const { resetQuizData } = quizSlice.actions;
export default quizSlice.reducer;
