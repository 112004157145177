import React from 'react';

import { Link } from 'react-router-dom';
import NavbarStandard from '../landing/NavbarStandard';
import FooterStandard from '../landing/FooterStandard';
import Section from 'components/common/Section';
import QuizesTable from './QuizesTable';
import { Card, Row, Breadcrumb } from 'react-bootstrap';

const Quiz = () => {
  return (
    <>
      <NavbarStandard />

      <Section className="text-center bg-200">
        <Breadcrumb className="mb-3">
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item as={Link} to="/quiz" active>
            Quizzes
          </Breadcrumb.Item>
        </Breadcrumb>

        <Card className="mb-3">
          <Card.Body>
            <Row className="g-0">
              <QuizesTable />
            </Row>
          </Card.Body>
        </Card>
      </Section>
      <FooterStandard />
    </>
  );
};

export default Quiz;
