import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const Account = forwardRef(ref => {
  return (
    <Card className="mb-3" id="account" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Account</h5>
      </Card.Header>
      <Card.Body>
        <h6>Eligibility</h6>
        <p className="fs-0 mb-0 fw-semi-bold">
          To create a quiz on EzQuize, you must:
        </p>
        <ol type="1">
          <li>
            Be an admin on the Discord server for which you are creating the
            quiz.
          </li>
          <li>Complete the signup process via Discord.</li>
          <li>Agree to these Terms.</li>
          <li>Provide true, complete, and up-to-date contact information.</li>
        </ol>
        <hr className="my-4" />
        <h6>Authorization</h6>
        <p className="mb-0 ps-3">
          Authorization on the EzQuize site is done via Discord. By linking your
          Discord account, you agree to abide by these Terms.
        </p>
        <hr className="my-4" />
        <h6>Closing your account</h6>
        <p className="mb-0 ps-3">
          You or we may terminate this Agreement at any time by giving notice in
          writing (including Discord messages). Once terminated, we may
          permanently delete your quizzes and all associated data.
        </p>
        <hr className="my-4" />
        <h6>Payment</h6>
        <p className="mb-0 ps-3">
          Payment for services is made using the USDT cryptocurrency on the
          Binance Smart Chain network via MetaMask. By using this payment
          method, you agree to its terms and conditions.
        </p>
        <hr className="my-4" />
        <h6>Account and Password</h6>
        <p className="mb-0 ps-3">
          You’re responsible for keeping your Discord account secure. Any
          unauthorized use of your account should be immediately reported to us.
          We’re not responsible for any losses due to compromised Discord
          accounts.
        </p>
      </Card.Body>
    </Card>
  );
});

export default Account;
