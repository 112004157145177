import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchQuestions = createAsyncThunk(
  'questions/fetchQuestions',
  async ({ token, questionSetId }, thunkAPI) => {
    // <-- Destructure the parameters here
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/question-set/${questionSetId}/questions`
      );
      console.log('questions: ', response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const questionsSlice = createSlice({
  name: 'questions',
  initialState: {
    questions: [],
    isLoading: false,
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchQuestions.pending, state => {
        state.questions = [];
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        state.questions = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchQuestions.rejected, (state, action) => {
        state.questions = [];
        state.isLoading = false;
        state.error = action.payload.error;
      });
  }
});

export default questionsSlice.reducer;
