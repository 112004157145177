import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchServersAndRoles = createAsyncThunk(
  'auth/fetchServersAndRoles',
  async ({ token, userId }, thunkAPI) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/discord/getsrv`,
        {
          userId
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const serversSlice = createSlice({
  name: 'servers',
  initialState: {
    isServersLoading: false,
    servers: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchServersAndRoles.pending, state => {
      state.isServersLoading = true;
      state.error = null;
    });
    builder.addCase(fetchServersAndRoles.fulfilled, (state, action) => {
      state.servers = action.payload.servers;
      state.isServersLoading = false;
    });
    builder.addCase(fetchServersAndRoles.rejected, (state, action) => {
      state.isServersLoading = false;
      state.error = action.payload.error;
    });
  }
});

export default serversSlice.reducer;
