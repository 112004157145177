import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const Account = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="account" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Account</h5>
      </Card.Header>
      <Card.Body>
        <h6>Account Creation</h6>
        <p className="fs-0 mb-0 fw-semi-bold">
          To create quizzes on our platform, you must register by connecting
          your Discord account. This process involves:
        </p>
        <ul>
          <li>
            Providing accurate and up-to-date personal information through your
            Discord account.
          </li>
          <li>Ensuring the security of your Discord credentials.</li>
          <li>
            Agreeing to our terms and conditions during the registration
            process.
          </li>
        </ul>
        <hr className="my-4" />
        <h6>Quiz Participation</h6>
        <p className="mb-0 ps-3">
          No site registration is needed to participate in quizzes. Simply add
          our bot to the required server, and you're ready to start.
        </p>
        <hr className="my-4" />
        <h6>Account Security</h6>
        <p className="mb-0 ps-3">
          You are responsible for maintaining the security of your account.
          Please notify us immediately if you suspect any unauthorized access to
          your account. We are not liable for any losses resulting from
          unauthorized use of your account.
        </p>
        <hr className="my-4" />
        <h6>Account Termination</h6>
        <p className="mb-0 ps-3">
          You can terminate your account at any time by contacting us. Upon
          termination, your data will be deleted in accordance with our data
          retention policy, except where retention is required by law. Please
          note that we do not store any data related to Web3.
        </p>
        <hr className="my-4" />
        <h6>Account Suspension</h6>
        <p className="mb-0 ps-3">
          We reserve the right to suspend or terminate your account if you
          violate our terms of service or engage in activities that harm our
          services or other users.
        </p>
      </Card.Body>
    </Card>
  );
});

export default Account;
