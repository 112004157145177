import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, InputGroup, FormControl, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { addNewQuestion } from '../../../../../store/questionDetails'; // Assuming you have an action to add a new question

const AddQuestionModal = ({
  showModal,
  handleClose,
  questionSet,
  onQuestionAdded
}) => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);

  const [questionText, setQuestionText] = useState(''); // Renamed to avoid confusion with the question object
  const [answers, setAnswers] = useState([
    { answer: '', isCorrect: false },
    { answer: '', isCorrect: false }
  ]);

  const addAnswerOption = () => {
    if (answers.length < 4) {
      setAnswers([...answers, { answer: '', isCorrect: false }]);
    }
  };

  const removeAnswerOption = () => {
    if (answers.length > 2) {
      setAnswers(answers.slice(0, -1));
    }
  };

  const handleSave = () => {
    const question = {
      question: questionText,
      answers: answers,
      questions: questionSet
    };
    console.log('question save', question);
    dispatch(addNewQuestion({ token, question }));
    handleClose();
    if (onQuestionAdded) onQuestionAdded();
  };

  return (
    <Modal show={showModal} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Question</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          {/* Question Field */}
          <InputGroup className="mb-3">
            <InputGroup.Text>?</InputGroup.Text>
            <FormControl
              placeholder="Question"
              value={questionText}
              onChange={e => setQuestionText(e.target.value)}
            />
          </InputGroup>

          {/* Answer Fields */}
          {answers.map((answer, index) => (
            <InputGroup key={index} className="mb-3">
              <InputGroup.Text>
                {String.fromCharCode(65 + index)}
              </InputGroup.Text>
              <InputGroup.Radio
                name="answer"
                checked={answer.isCorrect}
                onChange={() => {
                  const updatedAnswers = answers.map((ans, idx) =>
                    idx === index
                      ? { ...ans, isCorrect: true }
                      : { ...ans, isCorrect: false }
                  );
                  setAnswers(updatedAnswers);
                }}
              />
              <FormControl
                placeholder={`Answer ${String.fromCharCode(65 + index)}`}
                value={answer.answer}
                onChange={e => {
                  const updatedAnswers = [...answers];
                  updatedAnswers[index].answer = e.target.value;
                  setAnswers(updatedAnswers);
                }}
              />
              {(index === 2 || index === 3) && (
                <Button variant="danger" onClick={removeAnswerOption}>
                  Delete
                </Button>
              )}
            </InputGroup>
          ))}

          {answers.length < 4 && (
            <Button variant="primary" onClick={addAnswerOption}>
              Add Answer
            </Button>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddQuestionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  questionSet: PropTypes.string.isRequired,
  onQuestionAdded: PropTypes.func.isRequired
};

export default AddQuestionModal;
