import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import quizzesReducer from './quizzesSlice';
import quizReducer from './quizSlice';
import questionsReducer from './questionsSlice';
import questionDetailsReducer from './questionDetails';
import usersReducer from './usersSlice';
import analyticsReducer from './analyticsSlice';
import serversReducer from './serversSlice';
import questionGenerationReducer from './questionGenerationSlice'; // Импорт нового слайса

const store = configureStore({
  reducer: {
    auth: authReducer,
    quizzes: quizzesReducer,
    quiz: quizReducer,
    questions: questionsReducer,
    questionDetails: questionDetailsReducer,
    users: usersReducer,
    analytics: analyticsReducer,
    servers: serversReducer,
    questionGeneration: questionGenerationReducer // Добавление нового слайса в хранилище
  }
});

export default store;
