import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const DataUsage = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="data-usage" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Data Usage</h5>
      </Card.Header>
      <Card.Body>
        <h6>How We Use Your Data</h6>
        <p className="fs-0 mb-0 fw-semi-bold">
          We use your data to provide and improve our services, including:
        </p>
        <ul>
          <li>Personalizing your experience based on your preferences.</li>
          <li>Processing transactions and sending related information.</li>
          <li>
            Providing customer support, including responding to your inquiries.
          </li>
          <li>Improving our website, products, and services.</li>
          <li>Communicating with you about updates, offers, and promotions.</li>
        </ul>
        <hr className="my-4" />
        <h6>Data Retention</h6>
        <p className="mb-0 ps-3">
          We retain your data only as long as necessary to fulfill the purposes
          for which it was collected, including legal or reporting requirements.
          After that, your data will be securely deleted or anonymized.
        </p>
        <hr className="my-4" />
        <h6>Sharing Your Data</h6>
        <p className="mb-0 ps-3">
          We do not share your personal data with third parties except as
          necessary to provide our services, comply with the law, or protect our
          rights. Any sharing of data with third-party service providers is done
          under strict contractual obligations.
        </p>
        <hr className="my-4" />
        <h6>Your Data Rights</h6>
        <p className="mb-0 ps-3">
          You have the right to access, correct, or delete your personal data
          held by us. You can also object to the processing of your data or
          request data portability under certain circumstances. To exercise
          these rights, please contact us.
        </p>
      </Card.Body>
    </Card>
  );
});

export default DataUsage;
