import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchQuizzes = createAsyncThunk(
  'quizzes/fetchQuizzes',
  async ({ token, userId }, thunkAPI) => {
    // <-- Destructure the parameters here
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/campaign/user/${userId}`
      );
      console.log('quizzes: ', response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const quizzesSlice = createSlice({
  name: 'quizzes',
  initialState: {
    quizzes: [],
    isLoading: false,
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchQuizzes.pending, state => {
      state.quizzes = [];
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchQuizzes.fulfilled, (state, action) => {
      state.quizzes = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchQuizzes.rejected, (state, action) => {
      state.quizzes = [];
      state.isLoading = false;
      state.error = action.payload.error;
    });
  }
});

export default quizzesSlice.reducer;
