import bw1 from 'assets/img/logos/b&w/6.png';
import bw2 from 'assets/img/logos/b&w/11.png';
import bw3 from 'assets/img/logos/b&w/2.png';
import bw4 from 'assets/img/logos/b&w/space-id.png';

export default [
  { src: bw1, height: 40 },
  { src: bw2, height: 45 },
  { src: bw3, height: 30 },
  { src: bw4, height: 30 }
];
