import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Landing from 'components/pages/landing/Landing';

import ErrorLayout from '../layouts/ErrorLayout';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import WizardSetup from 'components/wizard/wizardSetup';
import Wizard from 'components/wizard/Wizard';
import Terms from 'components/pages/terms/Terms';
import Quiz from 'components/pages/quizes/Quiz';
import QuizCard from 'components/pages/quizes/QuizCard';
import PrivacyPolicy from 'components/pages/privacy/PrivacyPolicy'; // Import the Privacy Policy page

const FalconRoutes = () => {
  return (
    <Routes>
      {/* Landing Page */}
      <Route path="/" element={<Landing />} />
      {/* Error Pages */}
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>
      {/* Wizard Setup */}
      <Route element={<WizardSetup />}>
        <Route path="/wizard" element={<Wizard validation={true} />} />
      </Route>
      {/* Legal Pages */}
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />{' '}
      {/* Privacy Policy Route */}
      {/* Quiz Pages */}
      <Route path="/quiz" element={<Quiz />} />
      <Route path="/quiz/:quizId" element={<QuizCard />} />
      {/* Redirect all unknown routes to 404 */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default FalconRoutes;
