import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';
import PropTypes from 'prop-types';

const AdvanceTableSearchBox = ({
  globalFilter,
  setGlobalFilter,
  setSearchQuery,
  placeholder = 'Search...',
  className,
  setSearchQuery: updateSearchQuery
}) => {
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
    setSearchQuery(value); // Update the searchQuery state
  }, 200);
  return (
    <InputGroup className={classNames(className, 'position-relative')}>
      <FormControl
        value={value || ''}
        onChange={({ target: { value } }) => {
          setValue(value);
          onChange(value);
          updateSearchQuery(value);
        }}
        size="sm"
        id="search"
        placeholder={placeholder}
        type="search"
        className="shadow-none"
      />
      <Button
        size="sm"
        variant="outline-secondary"
        className="border-300 hover-border-secondary"
      >
        <FontAwesomeIcon icon="search" className="fs--1" />
      </Button>
    </InputGroup>
  );
};
AdvanceTableSearchBox.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  updateSearchQuery: PropTypes.func.isRequired
};
export default AdvanceTableSearchBox;
