import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Liability = forwardRef(ref => {
  return (
    <Card className="mb-3" id="liability" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Liability</h5>
      </Card.Header>
      <Card.Body>
        <h6>Indemnity</h6>
        <p className="mb-0 ps-3">
          You shall defend, indemnify, and hold us harmless against claims
          arising out of your use of the Service.
        </p>
        <hr className="my-4" />
        <h6>Limitation of Liability</h6>
        <p className="mb-0 ps-3">
          EzQuize shall not be liable for any indirect, incidental, or
          consequential damages, including but not limited to, loss of data or
          loss of profits, arising out of or in connection with the use of our
          services.
        </p>
        <hr className="my-4" />
        <h6>Consumer Rights</h6>
        <p className="mb-0 ps-3">
          If you are a consumer, nothing in these terms will affect your
          statutory rights. If we fail to comply with these terms, we are
          responsible for loss or damage you suffer that is a foreseeable result
          of our breach.
        </p>
        <hr className="my-4" />
        <h6>Force Majeure</h6>
        <p className="mb-0 ps-3">
          EzQuize will not be liable for any failure to perform due to
          unforeseen circumstances beyond our reasonable control, including but
          not limited to, natural disasters, internet outages, or governmental
          actions.
        </p>
        <hr className="my-4" />
        <h6>Data Processing</h6>
        <p className="mb-0 ps-3">
          To the extent that we process any personal data on your behalf, in the
          provision of the service, you agree to comply with the requirements of
          all applicable data protection laws. For more details, please refer to
          our <Link to="/privacy-policy">Privacy Policy</Link>.
        </p>
      </Card.Body>
    </Card>
  );
});

export default Liability;
