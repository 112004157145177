import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const DataSharing = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="data-sharing" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Data Sharing</h5>
      </Card.Header>
      <Card.Body>
        <h6>How We Share Your Data</h6>
        <p className="fs-0 mb-0 fw-semi-bold">
          We may share your data with third parties in the following
          circumstances:
        </p>
        <ul>
          <li>
            <strong>Service Providers:</strong> We may share your data with
            third-party service providers who perform services on our behalf,
            such as payment processing, data analysis, email delivery, hosting
            services, customer service, and marketing efforts.
          </li>
          <li>
            <strong>Business Transfers:</strong> In the event of a merger,
            acquisition, or sale of all or a portion of our assets, your data
            may be transferred to the acquiring company.
          </li>
          <li>
            <strong>Legal Requirements:</strong> We may disclose your data if
            required by law, such as to comply with a subpoena or similar legal
            process, or when we believe in good faith that disclosure is
            necessary to protect our rights, your safety, or the safety of
            others, investigate fraud, or respond to a government request.
          </li>
          <li>
            <strong>With Your Consent:</strong> We may share your data with
            third parties if you have given us your consent to do so.
          </li>
        </ul>
        <hr className="my-4" />
        <h6>Data Sharing with Affiliates</h6>
        <p className="mb-0 ps-3">
          We may share your data with our affiliates, in which case we will
          require those affiliates to honor this Privacy Policy. Affiliates
          include our parent company and any subsidiaries, joint venture
          partners, or other companies that we control or that are under common
          control with us.
        </p>
        <hr className="my-4" />
        <h6>International Data Transfers</h6>
        <p className="mb-0 ps-3">
          Your data may be transferred to — and maintained on — computers
          located outside of your state, province, country, or other
          governmental jurisdiction where the data protection laws may differ
          from those of your jurisdiction. If you are located outside the United
          States and choose to provide information to us, please note that we
          transfer the data, including personal data, to the United States and
          process it there.
        </p>
        <hr className="my-4" />
        <h6>Third-Party Analytics</h6>
        <p className="mb-0 ps-3">
          We may use third-party service providers to monitor and analyze the
          use of our service. These third parties have access to your data only
          to perform these tasks on our behalf and are obligated not to disclose
          or use it for any other purpose.
        </p>
      </Card.Body>
    </Card>
  );
});

export default DataSharing;
