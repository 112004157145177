import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from 'store/authSlice';
import Avatar from 'components/common/Avatar';

// Import local avatar image
import avatarImage from '../../../assets/img/team/avatar.png';

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const avatarFromStore = useSelector(state => state.auth.avatar); // getting avatar from the store
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const user = useSelector(state => state.auth.user);
  const username = isAuthenticated && user ? user.username : null;

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  return (
    <>
      <Dropdown navbar={true} as="li">
        <Dropdown.Toggle
          bsPrefix="toggle"
          as="div" // Changed from Link to div
          className="pe-0 ps-2 nav-link d-flex align-items-center"
          style={{ cursor: 'pointer' }}
        >
          {/* Use avatar from the store if it exists, otherwise use the local image */}
          <div className="me-2 d-flex justify-content-center">{username}</div>
          <Avatar src={avatarFromStore || avatarImage} />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
          <div className="bg-white rounded-2 py-2 dark__bg-1000">
            <Dropdown.Divider />

            <Dropdown.Item as={Link} to="/quiz">
              Quizzes
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to="https://discord.com/api/oauth2/authorize?client_id=1071731969819430922&permissions=8&scope=bot"
            >
              Invite BOT
            </Dropdown.Item>
            <Dropdown.Divider />

            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default ProfileDropdown;
