import illustration1 from 'assets/img/icons/spot-illustrations/50.png';
import illustration2 from 'assets/img/icons/spot-illustrations/49.png';
import illustration3 from 'assets/img/icons/spot-illustrations/48.png';

export default [
  {
    icon: ['far', 'lightbulb'],
    iconText: 'SETUP',
    color: 'danger',
    title: 'Step by step',
    description: `EzQuize: Making quiz setup a breeze on Discord! With a straightforward step-by-step guide, you'll set up engaging quizzes in no time. 1. Choose your questions. 2. Set the rules. 3. Launch your quiz. It's as simple as 1-2-3. Dive into intuitive settings and create interactive fun today!`,
    image: illustration1
  },
  {
    icon: ['far', 'object-ungroup'],
    iconText: 'ROLE',
    color: 'info',
    title: 'Easy role grant',
    description: `EzQuize: Your hassle-free tool for Discord role management! With just a few clicks, you can assign roles based on quiz outcomes, helping you structure your server effortlessly. Manage roles, streamline access, and foster engagement - all in one, easy-to-use bot. Role distribution has never been this simple!`,
    image: illustration2,
    inverse: true
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'ANALYTICS',
    color: 'success',
    title: 'Turning data into insights',
    description: `Get instant access to comprehensive quiz analytics, tracking participant progress, scores, and trends with ease. Our intuitive dashboard puts vital statistics at your fingertips, helping you understand your audience better. Make informed decisions and boost interaction - all with the power of EzQuize analytics.`,
    image: illustration3
  }
];
