import React, { useEffect, useState } from 'react';
import NavbarStandard from '../landing/NavbarStandard';
import FooterStandard from '../landing/FooterStandard';
import Section from 'components/common/Section';
import { Card, Row, Col, Breadcrumb, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { fetchQuizById } from '../../../store/quizSlice';
import { useParams } from 'react-router-dom';
import QuizDetailsOverview from './quizDetails/quizDetailsOverview';
import QuizDetailsTabs from './quizDetails/quizDetailsTabs';

const QuizesDetails = () => {
  const { quizId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user); // Get the user object
  const userId = user ? user.id : null;
  const [isLoading, setIsLoading] = useState(true);

  const token = useSelector(state => state.auth.token);
  useEffect(() => {
    dispatch(fetchQuizById({ token, quizId })).then(() => {
      setIsLoading(false);
    });
  }, [dispatch, token, quizId]);
  const quiz = useSelector(state => state.quiz);
  console.log(`userId:  `, userId);
  console.log(`isLoading:  `, isLoading);
  return (
    <>
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <>
          <NavbarStandard />

          <Section className="bg-200">
            <Breadcrumb className="mb-3">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/quiz">Quizzes</Breadcrumb.Item>
              <Breadcrumb.Item active>{quiz.name}</Breadcrumb.Item>
            </Breadcrumb>
            <Card className="mb-3">
              <Card.Body>
                <Row>
                  <Col xs={12}>
                    <QuizDetailsOverview quiz={quiz} />
                  </Col>
                </Row>
                <Row>
                  {isLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    <>
                      <QuizDetailsTabs />
                    </>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Section>
          <FooterStandard />
        </>
      )}
    </>
  );
};

export default QuizesDetails;
