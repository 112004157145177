import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button, Spinner } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { fetchQuizzes } from '../../../store/quizzesSlice'; // Ensure this import is correct
import { fetchServersAndRoles } from '../../../store/serversSlice';
import Flex from 'components/common/Flex';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import QuizCreationModal from './quizDetails/modal/addQuizModal';

const columns = [
  {
    accessor: 'name',
    Header: 'Quiz Name',
    headerProps: {
      className: 'py-3 ps-0'
    },
    cellProps: {
      className: 'ps-0'
    },
    Cell: rowData => {
      const quizId = rowData.row.original._id;
      return (
        <Link to={`/quiz/${quizId}`} className="text-800">
          <Flex alignItems="center">
            <h6 className="mb-0 ps-2">{rowData.row.original.name}</h6>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'date',
    Header: 'Start Date',
    Cell: rowData => {
      // Create a new Date object from the startDate string
      const date = new Date(rowData.row.original.startDate);

      // Format the date to "Month Day, Year"
      const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });

      return <p className="mb-0">{formattedDate}</p>;
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    Cell: rowData => {
      return (
        <SoftBadge pill bg={rowData.row.original.status} className="me-2">
          {rowData.row.original.status}
        </SoftBadge>
      );
    }
  },
  {
    accessor: 'Action',
    Header: 'Action',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    disableSortBy: true,
    Cell: () => (
      <div>
        <CardDropdown btnRevealClass="btn-reveal-sm" drop="start" />
      </div>
    )
  }
];

const QuizesTable = () => {
  const dispatch = useDispatch();

  const { quizzes } = useSelector(state => state.quizzes);
  const user = useSelector(state => state.auth.user); // Get the user object
  const userId = user ? user.id : null;
  const servers = useSelector(state => state.servers.servers); // Extract the list of servers from the servers slice
  const [showModal, setShowModal] = useState(false); // State to manage the visibility of the QuizCreationModal

  const openModal = () => setShowModal(true); // Function to open the QuizCreationModal
  const closeModal = () => setShowModal(false); // Function to close the QuizCreationModal

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const totalPages = Math.ceil(quizzes.length / itemsPerPage); // Calculate total pages

  const displayedQuizzes = quizzes.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const storedToken = localStorage.getItem('authToken');

  useEffect(() => {
    const shouldOpenModal = window.location.search.includes('create-quiz');
    if (shouldOpenModal) {
      setShowModal(true);
    }

    dispatch(fetchQuizzes({ storedToken, userId }));

    if (
      servers &&
      servers.length > 0 &&
      servers.every(server => server.roles && server.roles.length > 0)
    ) {
      console.log('servers loaded');
    } else {
      dispatch(fetchServersAndRoles({ storedToken, userId }));
    }
  }, [dispatch, servers, storedToken, userId]);

  return (
    <>
      {!userId ? (
        <Flex justifyContent="center" className="p-2 mb-2">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Flex>
      ) : (
        <>
          <Flex justifyContent="center" className="p-2 mb-2">
            <Button variant="primary" className="mb-1" onClick={openModal}>
              Create Quiz
            </Button>
          </Flex>
          <QuizCreationModal
            showModal={showModal}
            handleClose={closeModal}
            servers={servers}
          />
          <Card.Body>
            <AdvanceTableWrapper
              columns={columns}
              data={displayedQuizzes} // Use displayedQuizzes here
              sortable
              pagination
              perPage={itemsPerPage}
            >
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100"
                tableProps={{
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <Card.Footer>
                <AdvanceTableFooter
                  rowCount={quizzes.length}
                  pageSize={itemsPerPage}
                  page={displayedQuizzes}
                  setPageSize={setItemsPerPage}
                  pageIndex={currentPage}
                  canPreviousPage={currentPage > 1}
                  canNextPage={currentPage < totalPages}
                  nextPage={() =>
                    setCurrentPage(prev => Math.min(prev + 1, totalPages))
                  }
                  previousPage={() =>
                    setCurrentPage(prev => Math.max(prev - 1, 1))
                  }
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </Card.Footer>
            </AdvanceTableWrapper>
          </Card.Body>
        </>
      )}
    </>
  );
};

export default QuizesTable;
