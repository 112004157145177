import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const UserRights = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="user-rights" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Your Rights</h5>
      </Card.Header>
      <Card.Body>
        <h6>Access to Your Data</h6>
        <p className="fs-0 mb-0 fw-semi-bold">
          You have the right to access the personal data we hold about you. Upon
          request, we will provide you with a copy of your data in a commonly
          used format.
        </p>
        <hr className="my-4" />
        <h6>Correction of Your Data</h6>
        <p className="mb-0 ps-3">
          If the information we hold about you is inaccurate or incomplete, you
          have the right to request correction or completion. We will promptly
          update our records and inform you once the correction is made.
        </p>
        <hr className="my-4" />
        <h6>Deletion of Your Data</h6>
        <p className="mb-0 ps-3">
          You have the right to request the deletion of your personal data. We
          will delete your data upon request, except where we are required to
          retain it by law or for legitimate business purposes.
        </p>
        <hr className="my-4" />
        <h6>Data Portability</h6>
        <p className="mb-0 ps-3">
          You have the right to request that your data be transferred to another
          service provider. Upon request, we will provide your data in a
          structured, commonly used, and machine-readable format.
        </p>
        <hr className="my-4" />
        <h6>Objection to Data Processing</h6>
        <p className="mb-0 ps-3">
          You have the right to object to the processing of your personal data
          for certain purposes, including direct marketing. We will stop
          processing your data upon request unless there are compelling
          legitimate grounds for the processing.
        </p>
        <hr className="my-4" />
        <h6>Withdrawal of Consent</h6>
        <p className="mb-0 ps-3">
          If you have provided consent for the processing of your data, you have
          the right to withdraw your consent at any time. This will not affect
          the lawfulness of any processing carried out before your withdrawal.
        </p>
        <hr className="my-4" />
        <h6>Filing a Complaint</h6>
        <p className="mb-0 ps-3">
          If you believe that your rights have been violated, you have the right
          to file a complaint with a data protection authority. We encourage you
          to contact us first so we can address your concerns.
        </p>
      </Card.Body>
    </Card>
  );
});

export default UserRights;
