import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const createUser = createAsyncThunk(
  'auth/createUser',
  async (userDetails, thunkAPI) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/register`,
        userDetails
      );
      if (response.status === 200) {
        // The user has been created, now call setToken to authenticate the user
        thunkAPI.dispatch(setToken(response.data.token));
      } else {
        // Return an error if the user could not be created
        return thunkAPI.rejectWithValue({ error: response.data.error });
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchUser = createAsyncThunk(
  'auth/fetchUser',
  async (token, thunkAPI) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    localStorage.setItem('authToken', token);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/profile`
      );
      console.log('response getProfile: ', response);
      thunkAPI.dispatch(setToken(token));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const setToken = createAsyncThunk(
  'auth/setToken',
  async (token, thunkAPI) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    localStorage.setItem('authToken', token);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/profile`
      );
      console.log('response:', response.data);
      const avatarUrl = response.data.avatar
        ? `https://cdn.discordapp.com/avatars/${response.data.discordId}/${response.data.avatar}.webp?size=80`
        : false;

      return {
        user: response.data.username,
        avatar: avatarUrl,
        id: response.data._id,
        token
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const handleDiscordCallback = createAsyncThunk(
  'auth/handleDiscordCallback',
  async (code, thunkAPI) => {
    console.log('code: ', code);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/discord/callback`,
        {
          code
        }
      );
      const token = res.data.token;
      console.log('token: ', token);
      thunkAPI.dispatch(setToken(token));
      return token;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isAuthenticated: false,
    isLoading: false,
    error: null,
    avatar: null,
    servers: []
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    logout: state => {
      state.user = null;
      state.isAuthenticated = false;
      state.avatar = null;
      localStorage.removeItem('authToken');
    }
  },
  extraReducers: builder => {
    builder.addCase(handleDiscordCallback.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(handleDiscordCallback.fulfilled, state => {
      state.isAuthenticated = true;
      state.isLoading = false;
    });
    builder.addCase(handleDiscordCallback.rejected, (state, action) => {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.error = action.payload.error;
    });
    builder.addCase(fetchUser.pending, state => {
      state.user = null;
      state.isAuthenticated = false;
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.isLoading = false;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.user = null;
      state.isAuthenticated = false;
      state.isLoading = false;
      state.error = action.payload.error;
    });
    builder.addCase(createUser.pending, state => {
      state.user = null;
      state.isAuthenticated = false;
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.avatar = action.payload.avatar;
      state.isAuthenticated = true;
      state.isLoading = false;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.user = null;
      state.isAuthenticated = false;
      state.isLoading = false;
      state.error = action.payload.error;
    });
    builder.addCase(setToken.pending, state => {
      state.user = null;
      state.isAuthenticated = false;
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(setToken.fulfilled, (state, action) => {
      state.user = {
        username: action.payload.user,
        id: action.payload.id
      };
      state.avatar = action.payload.avatar;
      state.isAuthenticated = true;
      state.isLoading = false;
      state.token = action.payload.token;
    });
    builder.addCase(setToken.rejected, (state, action) => {
      state.user = null;
      state.isAuthenticated = false;
      state.isLoading = false;
      state.error = action.payload.error;
    });
  }
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
