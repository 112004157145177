import React, { useRef } from 'react';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import NavbarStandard from '../landing/NavbarStandard.js';
import FooterStandard from '../landing/FooterStandard.js';
import Account from './Account.js';
import DataCollection from './DataCollection.js';
import DataUsage from './DataUsage.js';
import DataSharing from './DataSharing.js';
import UserRights from './UserRights.js';
import ContactInformation from './ContactInformation.js';
import useScrollSpy from 'react-use-scrollspy';
import Section from 'components/common/Section';

const PrivacyPolicy = () => {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  return (
    <>
      <NavbarStandard />
      <Section className="bg-200">
        <Card className="mb-3">
          <Card.Body>
            <h1>Privacy Policy</h1>
            <Row className="g-0">
              <Col lg={8} className="pe-lg-2 order-1 order-lg-0">
                <Account ref={sectionRefs[0]} />
                <DataCollection ref={sectionRefs[1]} />
                <DataUsage ref={sectionRefs[2]} />
                <DataSharing ref={sectionRefs[3]} />
                <UserRights ref={sectionRefs[4]} />
                <ContactInformation ref={sectionRefs[5]} />
              </Col>
              <Col lg={4} className="ps-lg-2 mb-3">
                <div className="sticky-sidebar">
                  <Card className="sticky-top">
                    <Card.Header className="border-bottom">
                      <h6 className="mb-0 fs-0">Navigate</h6>
                    </Card.Header>
                    <Card.Body>
                      <div
                        id="privacy-sidebar"
                        className="privacy-sidebar nav flex-column fs--1"
                      >
                        <Nav.Item>
                          <Nav.Link
                            href="#account"
                            className="nav-link px-0 py-1"
                            active={activeSection === 0}
                          >
                            Account
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            href="#data-collection"
                            className="nav-link px-0 py-1"
                            active={activeSection === 1}
                          >
                            Data Collection
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            href="#data-usage"
                            className="nav-link px-0 py-1"
                            active={activeSection === 2}
                          >
                            Data Usage
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            href="#data-sharing"
                            className="nav-link px-0 py-1"
                            active={activeSection === 3}
                          >
                            Data Sharing
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            href="#user-rights"
                            className="nav-link px-0 py-1"
                            active={activeSection === 4}
                          >
                            User Rights
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            href="#contact-information"
                            className="nav-link px-0 py-1"
                            active={activeSection === 5}
                          >
                            Contact Information
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Section>
      <FooterStandard />
    </>
  );
};

export default PrivacyPolicy;
