import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const addNewQuestion = createAsyncThunk(
  'questions/addNewQuestion',
  async ({ token, question }, thunkAPI) => {
    console.log('question', question);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/single-question/create`,
        question
      );
      console.log('added question details: ', response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchQuestionDetails = createAsyncThunk(
  'questions/fetchQuestionDetails',
  async ({ token, questionId }, thunkAPI) => {
    console.log('questionId: ', questionId);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/single-question/${questionId}`
      );
      console.log('question details: ', response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const editQuestionDetails = createAsyncThunk(
  'questions/editQuestionDetails',
  async ({ token, questionId, updatedData }, thunkAPI) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/single-question/${questionId}`,
        updatedData
      );
      console.log('updated question details: ', response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  'questions/deleteQuestion',
  async ({ token, questionId }, thunkAPI) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/single-question/${questionId}`
      );
      return questionId;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const questionsSlice = createSlice({
  name: 'questionDetails',
  initialState: {
    questionDetails: {},
    isLoading: false,
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchQuestionDetails.pending, state => {
        state.questionDetails = {};
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchQuestionDetails.fulfilled, (state, action) => {
        state.questionDetails = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchQuestionDetails.rejected, (state, action) => {
        state.questionDetails = {};
        state.isLoading = false;
        state.error = action.payload.error;
      })
      // Handling editQuestionDetails
      .addCase(editQuestionDetails.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(editQuestionDetails.fulfilled, (state, action) => {
        state.questionDetails = action.payload;
        state.isLoading = false;
      })
      .addCase(editQuestionDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      })
      .addCase(addNewQuestion.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewQuestion.fulfilled, (state, action) => {
        state.questionDetails = action.payload;
        state.isLoading = false;
      })
      .addCase(addNewQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      })
      .addCase(deleteQuestion.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteQuestion.fulfilled, state => {
        // Assuming you have a list of questions in your state,
        // remove the deleted question from the list
        // state.questions = state.questions.filter(q => q.id !== action.payload);
        state.isLoading = false;
      })
      .addCase(deleteQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      });
  }
});

export default questionsSlice.reducer;
