import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import QuestionsTable from './questionsTab';
import Settings from './settingsTab';
import UsersTable from './usersTable';

const QuizDetailsTabs = () => {
  return (
    <div className="overflow-hidden mt-4">
      <Tab.Container defaultActiveKey="questions">
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link
              eventKey="questions"
              className="ps-0 cursor-pointer outline-none"
            >
              Questions
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="settings"
              className="px-2 px-md-3 cursor-pointer outline-none"
            >
              Settings
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="users"
              className="px-2 px-md-3 cursor-pointer outline-none"
            >
              Users
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="questions">
            <div className="mt-3" />
            <QuestionsTable />
          </Tab.Pane>
          <Tab.Pane eventKey="settings">
            <Settings />
          </Tab.Pane>
          <Tab.Pane eventKey="users">
            <UsersTable />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default QuizDetailsTabs;
