import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bg2 from 'assets/img/generic/bg-2.jpg';
import Section from 'components/common/Section';
import AppContext from 'context/Context';
import React, { useContext, useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Typewriter } from 'react-simple-typewriter';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const navigate = useNavigate();

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const [discordUrl, setDiscordUrl] = useState('');

  useEffect(() => {
    const discordAuthUrl = `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FRONTEND_URL}&response_type=code&scope=identify%20guilds%20email%20role_connections.write`;
    setDiscordUrl(discordAuthUrl);
  }, []);

  const handleButtonClick = () => {
    if (!isAuthenticated) {
      window.location.href = discordUrl;
    } else {
      navigate('/quiz?create-quiz');
    }
  };

  return (
    <Section
      className="py-0 overflow-hidden light"
      image={bg2}
      position="center bottom"
      overlay
    >
      <Row className="justify-content-start align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          md={11}
          lg={8}
          xl={4}
          className="pb-7 pb-xl-9 text-left text-xl-start ml-md-5"
        >
          <h1 className="text-white fw-light">
            Crafting{' '}
            <span className="fw-bold">
              <Typewriter
                words={['quiz', 'certification', 'attestation', 'exam']}
                loop={false}
                cursor={!isRTL ? true : false}
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
            <br />
            on Discord made easy
          </h1>
          <p className="lead text-white opacity-75">
            With effortless setup, user-friendly interface, and insightful
            analytics, we're transforming learning into a fun experience. Choose
            EzQuize, make learning engaging!
          </p>
          <Button
            as={isAuthenticated ? 'button' : 'button'}
            variant="outline-light"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            onClick={handleButtonClick}
          >
            Start creating quizzes
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Button>
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;
