import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Dropdown, Button, Spinner } from 'react-bootstrap';
import { fetchQuestions } from '../../../../store/questionsSlice';
import { deleteQuestion } from '../../../../store/questionDetails';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import CardDropdown from 'components/common/CardDropdown';
import EditQuestionModal from './modal/editQuestionModal';
import Flex from 'components/common/Flex';
import AddQuestionModal from './modal/addQuestionModal';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import GenerateQuestionsModal from './modal/generateQuestionsModal'; // Импортируем модальное окно

const QuestionsTable = () => {
  const dispatch = useDispatch();
  const questionSetId = useSelector(state => state.quiz.questionSet);
  const questionsTableData = useSelector(state => state.questions.questions);
  const token = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth.user);
  const userId = user ? user.id : null;

  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const [generateModalShow, setGenerateModalShow] = useState(false); // Состояние для модального окна генерации
  const [questions, setQuestions] = useState(questionsTableData);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const totalPages = Math.ceil(questions.length / itemsPerPage);

  const displayedQuestions = questions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const openAddModal = () => {
    setAddModalShow(true);
  };
  const closeAddModal = () => setAddModalShow(false);

  const openGenerateModal = () => {
    setGenerateModalShow(true); // Открываем модальное окно генерации
  };
  const closeGenerateModal = () => setGenerateModalShow(false); // Закрываем модальное окно генерации

  const closeModal = () => setEditModalShow(false);

  const handleEditClick = questionId => {
    setEditModalShow(true);
    setSelectedQuestionId(questionId);
  };

  const handleDeleteClick = async questionId => {
    // Dispatch deleteQuestion action
    await dispatch(deleteQuestion({ token, questionId }));

    // Refresh the questions list
    dispatch(fetchQuestions({ token, questionSetId }));
  };

  const onQuestionAdded = () => {
    dispatch(fetchQuestions({ token, questionSetId }));
  };

  const onQuestionsGenerated = () => {
    dispatch(fetchQuestions({ token, questionSetId }));
  };

  const columns = [
    {
      accessor: 'question',
      Header: 'Question',
      Cell: rowData => <p>{rowData.row.original.question}</p>
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const questionId = rowData.row.original._id;
        return (
          <>
            <CardDropdown>
              <Dropdown.Item
                key={questionId}
                onClick={() => handleEditClick(questionId)}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Divider as="div" />
              <Dropdown.Item
                href="#!"
                className="text-danger"
                onClick={() => handleDeleteClick(questionId)}
              >
                Delete
              </Dropdown.Item>
            </CardDropdown>
            {selectedQuestionId === questionId && (
              <EditQuestionModal
                editModalShow={editModalShow}
                questionId={questionId}
                handleClose={closeModal}
              />
            )}
          </>
        );
      }
    }
  ];

  useEffect(() => {
    dispatch(fetchQuestions({ token, questionSetId }));
  }, [dispatch, token, questionSetId]);

  useEffect(() => {
    setQuestions(questionsTableData);
  }, [questionsTableData]);

  return (
    <>
      {!userId ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <>
          <AdvanceTableWrapper
            columns={columns}
            data={displayedQuestions}
            sortable
            pagination
            perPage={itemsPerPage}
            className="pt-3"
            pageCount={totalPages}
          >
            <Flex justifyContent="center" className="p-2 mb-2">
              <Button variant="primary" className="mb-1" onClick={openAddModal}>
                Add Question
              </Button>
              <Button
                variant="secondary"
                className="mb-1 ms-2"
                onClick={openGenerateModal}
              >
                Generate 10 Questions
              </Button>
            </Flex>
            {questionSetId && (
              <AddQuestionModal
                showModal={addModalShow}
                handleClose={closeAddModal}
                questionSet={questionSetId}
                onQuestionAdded={onQuestionAdded}
              />
            )}
            {questionSetId && (
              <GenerateQuestionsModal
                showModal={generateModalShow}
                handleClose={closeGenerateModal}
                questionSet={questionSetId}
                onQuestionsGenerated={onQuestionsGenerated} // Обработчик для перезагрузки вопросов после генерации
              />
            )}
            <Card className="mb-3">
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  columns={columns}
                  data={displayedQuestions}
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              </Card.Body>
              <Card.Footer>
                <AdvanceTableFooter
                  rowCount={questions.length}
                  pageSize={itemsPerPage}
                  page={displayedQuestions}
                  setPageSize={setItemsPerPage}
                  pageIndex={currentPage}
                  canPreviousPage={currentPage > 1}
                  canNextPage={currentPage < totalPages}
                  nextPage={() =>
                    setCurrentPage(prev => Math.min(prev + 1, totalPages))
                  }
                  previousPage={() =>
                    setCurrentPage(prev => Math.max(prev - 1, 1))
                  }
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </>
      )}
    </>
  );
};

export default QuestionsTable;
