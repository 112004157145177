import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const Instructions = forwardRef((props, ref) => {
  return (
    <Card className="mb-3 mb-lg-0" id="instructions" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Instructions</h5>
      </Card.Header>
      <Card.Body>
        <h6 className="mb-3">Instructions for Quiz Creation:</h6>
        <table className="table table-striped table-bordered">
          <tbody>
            <tr className="bg-300">
              <td>
                <strong>Description</strong>
              </td>
              <td>
                <strong>Details</strong>
              </td>
            </tr>
            <tr>
              <td>Subject matter of the quiz</td>
              <td>
                Creating and managing quizzes for Discord servers via the
                EzQuize platform.
              </td>
            </tr>
            <tr>
              <td>Duration of the quiz</td>
              <td>As specified during quiz creation.</td>
            </tr>
            <tr>
              <td>Nature and purposes of the quiz</td>
              <td>
                Question text, answer choices, correct answers, and any other
                data provided through custom fields.
              </td>
            </tr>
            <tr>
              <td>Type of Data Collected</td>
              <td>
                Discord username, avatar, server id, user id, role name, role
                id, scores, and quiz responses.
              </td>
            </tr>
            <tr>
              <td>Categories of Participants</td>
              <td>Members of the Discord server where the quiz is hosted.</td>
            </tr>
            <tr>
              <td>Data Retention and Deletion</td>
              <td>
                Quiz data will be retained until the admin deletes the quiz or
                terminates their EzQuize account.
              </td>
            </tr>
          </tbody>
        </table>
        <p>By creating a quiz, you agree to these terms and conditions.</p>
        <p>Last update: Aug 20 2024</p>
      </Card.Body>
    </Card>
  );
});

export default Instructions;
