import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const ContactInformation = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="contact-information" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Contact Information</h5>
      </Card.Header>
      <Card.Body>
        <h6>Get in Touch</h6>
        <p className="mb-0">
          If you have any questions, concerns, or need assistance, feel free to
          reach out to us. We are available to help you through the following
          channels:
        </p>
        <hr className="my-4" />

        <h6>Email Support</h6>
        <p className="mb-0 ps-3">
          For direct inquiries, you can also reach us via email. We aim to
          respond within 24 hours.
        </p>
        <p className="ps-3">
          <a href="mailto:support@ezquize.com">support@ezquize.com</a>
        </p>
      </Card.Body>
    </Card>
  );
});

export default ContactInformation;
