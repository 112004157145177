import React from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';

const QuizForm = ({
  register,
  errors,
  servers,
  roles,
  premiumRoles,
  commandNAme = '/ezquize'
}) => {
  return (
    <>
      <WizardInput
        label="Name"
        name="name"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('name')
        }}
      />

      <WizardInput
        label="Description"
        name="description"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('description')
        }}
      />

      <WizardInput
        type="select"
        label="Select a Server"
        name="server"
        errors={errors}
        options={servers}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('server', {
            required: 'Server selection is required'
          })
        }}
      />

      <WizardInput
        type="select"
        label="Role from quiz"
        name="roleFromQuiz"
        errors={errors}
        options={roles}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('roleFromQuiz', {
            required: 'Role selection is required'
          })
        }}
      />

      <WizardInput
        type="select"
        label="Role for quiz - (Premium feature)"
        name="roleForQuizPremium"
        errors={errors}
        options={premiumRoles}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('roleForQuizPremium')
        }}
      />

      <WizardInput
        label="Command Name (Premium)"
        name="commandNAme"
        errors={errors}
        defaultValue={commandNAme}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('commandNAme', {
            required: 'Command name is required'
          })
        }}
      />
    </>
  );
};

QuizForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  watch: PropTypes.func,
  servers: PropTypes.arrayOf(PropTypes.string),
  roles: PropTypes.arrayOf(PropTypes.string),
  premiumRoles: PropTypes.arrayOf(PropTypes.string),
  commandNAme: PropTypes.string
};

export default QuizForm;
