import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const AIQuestionGeneration = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="subscriptions" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">
          AI Question Generation and Billing
        </h5>
      </Card.Header>
      <Card.Body>
        <h6>Free Quiz Creation</h6>
        <p className="mb-0 ps-3">
          Creating quizzes manually is completely free. You can add your own
          questions without any charges.
        </p>
        <hr className="my-4" />
        <h6>AI-Powered Question Generation</h6>
        <p className="mb-0 ps-3">
          AI-generated questions are a paid feature. Payments are made using the
          USDT cryptocurrency on the Binance Smart Chain network via Metamask.
          Invoice fees are payable in the specified currency and are exclusive
          of taxes.
        </p>
        <hr className="my-4" />
        <h6>Termination</h6>
        <p className="mb-0 ps-3">
          Either party may terminate this Agreement for material breach or
          insolvency.
        </p>
        <hr className="my-4" />
        <h6>Refund Policy</h6>
        <p className="mb-0 ps-3">
          No refunds are available except in cases of billing errors due to
          software bugs or other reasons. In such cases, refunds will be
          processed within 14 working days.
        </p>
      </Card.Body>
    </Card>
  );
});

export default AIQuestionGeneration;
