import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Accordion, Button, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { fetchQuestionDetails } from '../../../../store/questionDetails';

const UserAttemptsModal = ({ attemptsModalshow, handleClose, attempts }) => {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const questionDetailFromStore = useSelector(
    state => state.questionDetails.questionDetails
  );
  const [allQuestionDetails, setAllQuestionDetails] = useState([]);
  console.log('allQuestionDetails', allQuestionDetails);

  useEffect(() => {
    if (
      questionDetailFromStore &&
      Object.keys(questionDetailFromStore).length > 0
    ) {
      setAllQuestionDetails(prevDetails => [
        ...prevDetails,
        questionDetailFromStore
      ]);
    }
  }, [questionDetailFromStore]);

  useEffect(() => {
    attempts.forEach(attempt => {
      attempt.questions.forEach(q => {
        dispatch(fetchQuestionDetails({ token, questionId: q.question }));
      });
    });
  }, [attempts, dispatch, token]);
  return (
    <Modal size="lg" show={attemptsModalshow} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>User Attempts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion>
          {attempts.map((attempt, index) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Accordion.Header>
                # {index + 1}: {new Date(attempt.date).toLocaleString()}
              </Accordion.Header>
              <Accordion.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Question</th>
                      <th>User's Answer</th>
                      <th>Correct Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attempt.questions.map((q, qIndex) => {
                      const questionDetail = allQuestionDetails.find(
                        detail => detail._id === q.question
                      );
                      console.log(`questionDetail`, questionDetail);
                      const userAnswerText = questionDetail?.answers?.find(
                        answer => answer._id === q.userAnswer
                      );
                      console.log(`userAnswerText`, userAnswerText);

                      return (
                        <tr key={qIndex}>
                          <td>
                            {questionDetail?.question || 'Question not found'}
                          </td>
                          <td>
                            {userAnswerText?.answer || 'Answer not found'}
                          </td>
                          <td>{q.isCorrect ? 'Correct' : 'Incorrect'}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

UserAttemptsModal.propTypes = {
  attemptsModalshow: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  attempts: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          question: PropTypes.object.isRequired,
          userAnswer: PropTypes.object.isRequired,
          isCorrect: PropTypes.bool.isRequired
        })
      ).isRequired
    })
  ).isRequired
};

export default UserAttemptsModal;
