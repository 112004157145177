import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SimpleBarReact from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import {
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  DropdownButton,
  Dropdown,
  Spinner
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { editQuiz } from '../../../../store/quizSlice'; // Import the editQuiz action
import Flex from 'components/common/Flex';
import { fetchServersAndRoles } from '../../../../store/serversSlice';

const Settings = () => {
  const settingsData = useSelector(state => state.quiz);
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);

  const servers = useSelector(state => state.servers.servers);
  const [areRolesLoading, setAreRolesLoading] = useState(true);
  const user = useSelector(state => state.auth.user); // Get the user object
  const userId = user ? user.id : null;

  // Добавляем недостающие переменные
  const [isAccessRoleActive, setIsAccessRoleActive] = useState(
    !!settingsData.accessRole
  );

  useEffect(() => {
    setQuizName(settingsData.name || '');
    setDescription(settingsData.description || '');
    setServerId(settingsData.serverId || '');
    setCommandName(settingsData.commandName || '');
    setRoleName(settingsData.roleName || '');
    setAccessRoleName(settingsData.accessRole || '');
    setUsersLimit(settingsData.usersLimit || '');
    setAttemptsLimit(settingsData.attemptsLimit || '');
    setAttemptsNumberPerUser(settingsData.attemptsNumberPerUser || '');
    setNumberOfCorrectAnswers(settingsData.numberOfCorrectAnswers || '');
    setQuestionsNumberPerAttempt(settingsData.questionsNumberPerAttempt || '');
    setTimePerQuestion(settingsData.timePerQuestion || '');
    setTimeout(settingsData.timeout || '');
    setIsAccessRoleActive(!!settingsData.accessRole);

    if (
      servers &&
      servers.length > 0 &&
      servers.every(server => server.roles && server.roles.length > 0)
    ) {
      setAreRolesLoading(false);
    } else {
      dispatch(fetchServersAndRoles({ token, userId }));
    }

    const newStartDate = settingsData.startDate
      ? new Date(settingsData.startDate)
      : new Date();
    const newEndDate = settingsData.endDate
      ? new Date(settingsData.endDate)
      : new Date();
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }, [dispatch, settingsData, servers]);

  const [quizName, setQuizName] = useState(settingsData.name);
  const [description, setDescription] = useState(
    settingsData.description || ''
  );
  const [serverId, setServerId] = useState(settingsData.serverId || '');
  const [commandName, setCommandName] = useState(
    settingsData.commandName || ''
  );
  const [accessRole, setAccessRoleName] = useState(
    settingsData.accessRoleName || ''
  );
  const [roleName, setRoleName] = useState(settingsData.roleName || '');
  const [usersLimit, setUsersLimit] = useState(settingsData.usersLimit || '');
  const [attemptsLimit, setAttemptsLimit] = useState(
    settingsData.attemptsLimit || ''
  );
  const [attemptsNumberPerUser, setAttemptsNumberPerUser] = useState(
    settingsData.attemptsNumberPerUser || ''
  );
  const [numberOfCorrectAnswers, setNumberOfCorrectAnswers] = useState(
    settingsData.numberOfCorrectAnswers || ''
  );
  const [questionsNumberPerAttempt, setQuestionsNumberPerAttempt] = useState(
    settingsData.questionsNumberPerAttempt || ''
  );
  const [timePerQuestion, setTimePerQuestion] = useState(
    settingsData.timePerQuestion || ''
  );
  const [timeout, setTimeout] = useState(settingsData.timeout || '');

  const [hasChanged, setHasChanged] = useState(false);
  const selectedServer = servers.find(s => s.serverId === serverId);
  const selectedRole = selectedServer
    ? selectedServer.roles.find(r => r.name === roleName)
    : null;
  const selectedAccessRole = selectedServer
    ? selectedServer.roles.find(r => r.name === accessRole)
    : null;

  const [startDate, setStartDate] = useState(new Date(settingsData.startDate));
  const [endDate, setEndDate] = useState(new Date(settingsData.endDate));

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
    setHasChanged(true);
  };

  const handleSelectRoleChange = role => {
    setRoleName(role.name);
    setHasChanged(true);
  };

  const handleSelectAccessRoleChange = role => {
    setAccessRoleName(role.name);
    setHasChanged(true);
  };

  const handleInviteBot = () => {
    const url = `https://discord.com/api/oauth2/authorize?client_id=1071731969819430922&permissions=8&scope=bot`;
    window.open(url, '_blank');
  };

  const handleSave = () => {
    const updatedData = {
      name: quizName,
      description,
      serverId,
      commandName,
      roleName,
      accessRole,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      usersLimit,
      attemptsLimit,
      attemptsNumberPerUser,
      numberOfCorrectAnswers,
      questionsNumberPerAttempt,
      timePerQuestion,
      timeout
    };
    dispatch(
      editQuiz({
        token: token,
        quizId: settingsData._id,
        updatedData
      })
    );
    setHasChanged(false);
  };

  return (
    <>
      {areRolesLoading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Flex>
      ) : (
        <>
          <Form className="pt-3">
            <Row>
              <Col>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Quiz name</InputGroup.Text>
                  <FormControl
                    name="name"
                    value={quizName}
                    onChange={e => handleInputChange(e, setQuizName)}
                    placeholder="Enter Quiz name"
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Description</InputGroup.Text>
                  <FormControl
                    name="description"
                    value={description}
                    onChange={e => handleInputChange(e, setDescription)}
                    placeholder="Enter description"
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Command Name</InputGroup.Text>
                  <FormControl
                    name="commandName"
                    value={commandName}
                    onChange={e => handleInputChange(e, setCommandName)}
                    placeholder="Enter Command Name"
                  />
                </InputGroup>
                <div className="mt-4 mb-2 navbar-vertical-label-wrapper row">
                  <div className="navbar-vertical-label col-auto">Discord</div>
                  <div className="ps-0 col">
                    <hr className="mb-0 navbar-vertical-divider" />
                  </div>
                </div>

                <InputGroup className="mb-3">
                  <DropdownButton
                    variant="outline-secondary"
                    title={serverId ? 'Server' : 'Select Server'}
                    id="server-dropdown"
                    disabled
                  >
                    {servers.map(server => (
                      <Dropdown.Item
                        key={server.serverId}
                        onClick={e => {
                          setServerId(server.serverId),
                            handleInputChange(e, setServerId);
                        }}
                      >
                        {server.serverName}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  <FormControl
                    aria-label="Server Name"
                    value={selectedServer ? selectedServer.serverName : ''}
                    readOnly
                  />
                  <Button variant="outline-danger" onClick={handleInviteBot}>
                    Invite BOT
                  </Button>
                </InputGroup>
                <InputGroup className="mb-3">
                  <DropdownButton
                    variant="outline-secondary"
                    title={roleName ? 'Role Grant' : 'Select Role'}
                    id="role-dropdown"
                  >
                    <SimpleBarReact
                      style={{ maxHeight: '12rem', minWidth: '12rem' }}
                    >
                      {selectedServer.roles.map(role => (
                        <Dropdown.Item
                          key={role.id}
                          onClick={() => {
                            handleSelectRoleChange(role);
                          }}
                        >
                          {role.name}
                        </Dropdown.Item>
                      ))}
                    </SimpleBarReact>
                  </DropdownButton>
                  <FormControl
                    aria-label="Role Name"
                    value={selectedRole ? selectedRole.name : roleName}
                    readOnly
                  />
                </InputGroup>
                <Form.Check
                  type="switch"
                  id="accessRoleSwitch"
                  label="Enable Access Role"
                  checked={isAccessRoleActive}
                  onChange={e => {
                    const isChecked = e.target.checked;
                    setIsAccessRoleActive(isChecked);
                    if (!isChecked) {
                      setAccessRoleName('');
                      setHasChanged(true);
                    }
                  }}
                />

                <InputGroup className="mb-3">
                  <DropdownButton
                    variant="outline-secondary"
                    title={accessRole ? 'Access Role' : 'Select Access Role'}
                    id="access-role-dropdown"
                    disabled={!isAccessRoleActive}
                  >
                    <SimpleBarReact
                      style={{ maxHeight: '12rem', minWidth: '12rem' }}
                    >
                      {selectedServer.roles.map(role => (
                        <Dropdown.Item
                          key={role.id}
                          onClick={() => {
                            handleSelectAccessRoleChange(role);
                          }}
                        >
                          {role.name}
                        </Dropdown.Item>
                      ))}
                    </SimpleBarReact>
                  </DropdownButton>
                  <FormControl
                    aria-label="Access Role Name"
                    value={
                      selectedAccessRole ? selectedAccessRole.name : accessRole
                    }
                    readOnly
                    disabled={!isAccessRoleActive}
                  />
                </InputGroup>
                <div className="mt-4 mb-2 navbar-vertical-label-wrapper row">
                  <div className="navbar-vertical-label col-auto">
                    Scheduling
                  </div>
                  <div className="ps-0 col">
                    <hr className="mb-0 navbar-vertical-divider" />
                  </div>
                </div>

                <InputGroup className="mb-3">
                  <InputGroup.Text>
                    {startDate ? 'Start' : 'Select Date'}
                  </InputGroup.Text>
                  <DatePicker
                    selected={startDate}
                    onChange={date => {
                      setStartDate(date);
                      setHasChanged(true);
                    }}
                    className="form-control"
                    placeholderText="DD-MM-YYYY h:mm aa"
                    timeIntervals={5}
                    dateFormat="dd-MM-yyyy h:mm aa"
                    showTimeSelect
                    fixedHeight
                  />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text>
                    {endDate ? 'End' : 'Select Date'}
                  </InputGroup.Text>
                  <DatePicker
                    selected={endDate}
                    onChange={date => {
                      setEndDate(date);
                      setHasChanged(true);
                    }}
                    className="form-control"
                    placeholderText="DD-MM-YYYY h:mm aa"
                    timeIntervals={5}
                    dateFormat="dd-MM-yyyy h:mm aa"
                    showTimeSelect
                    fixedHeight
                    minDate={startDate}
                  />
                </InputGroup>
              </Col>
              <Col>
                <div className="mt-4 mb-2 navbar-vertical-label-wrapper row">
                  <div className="navbar-vertical-label col-auto">Options</div>
                  <div className="ps-0 col">
                    <hr className="mb-0 navbar-vertical-divider" />
                  </div>
                </div>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Total Members</InputGroup.Text>
                  <FormControl
                    name="usersLimit"
                    value={usersLimit || ''}
                    onChange={e => handleInputChange(e, setUsersLimit)}
                    placeholder="Enter Number of Total Members"
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Total Attempts</InputGroup.Text>
                  <FormControl
                    name="attemptsLimit"
                    value={attemptsLimit || ''}
                    onChange={e => handleInputChange(e, setAttemptsLimit)}
                    placeholder="Enter Attempts Limit Per Quiz"
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Attempts Number Per Member</InputGroup.Text>
                  <FormControl
                    name="attemptsNumberPerUser"
                    value={attemptsNumberPerUser || ''}
                    onChange={e =>
                      handleInputChange(e, setAttemptsNumberPerUser)
                    }
                    placeholder="Enter Attempts Number Per User"
                  />
                </InputGroup>
                <div className="mt-4 mb-2 navbar-vertical-label-wrapper row">
                  <div className="navbar-vertical-label col-auto">
                    Questions
                  </div>
                  <div className="ps-0 col">
                    <hr className="mb-0 navbar-vertical-divider" />
                  </div>
                </div>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Questions</InputGroup.Text>
                  <FormControl
                    name="questions"
                    value={questionsNumberPerAttempt || ''}
                    onChange={e =>
                      handleInputChange(e, setQuestionsNumberPerAttempt)
                    }
                    placeholder="Enter Number of Questions per Attempt"
                  />
                  <InputGroup.Text>per attempt</InputGroup.Text>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Passing Score</InputGroup.Text>
                  <FormControl
                    name="numberOfCorrectAnswers"
                    value={numberOfCorrectAnswers || ''}
                    onChange={e =>
                      handleInputChange(e, setNumberOfCorrectAnswers)
                    }
                    placeholder="Enter Number of Correct Answers for Passing"
                  />
                  <InputGroup.Text>correct answers</InputGroup.Text>
                </InputGroup>
                <div className="mt-4 mb-2 navbar-vertical-label-wrapper row">
                  <div className="navbar-vertical-label col-auto">Limits</div>
                  <div className="ps-0 col">
                    <hr className="mb-0 navbar-vertical-divider" />
                  </div>
                </div>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Time Per Question</InputGroup.Text>
                  <FormControl
                    name="timePerQuestion"
                    value={timePerQuestion || ''}
                    onChange={e => handleInputChange(e, setTimePerQuestion)}
                    placeholder="Enter Time In Seconds"
                  />
                  <InputGroup.Text>seconds</InputGroup.Text>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>Timeout</InputGroup.Text>
                  <FormControl
                    name="timeout"
                    value={timeout || ''}
                    onChange={e => handleInputChange(e, setTimeout)}
                    placeholder="Enter Timeout In Minutes"
                  />
                  <InputGroup.Text>minutes</InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center mt-3">
              <Col xs="auto">
                <Button
                  variant="primary"
                  onClick={handleSave}
                  disabled={!hasChanged}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default Settings;
