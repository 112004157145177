import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchQuizAnalytics = createAsyncThunk(
  'quizzes/fetchQuizAnalytics',
  async ({ token, quizId }, thunkAPI) => {
    // <-- Destructure the parameters here
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/analytics/quiz/${quizId}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const quizAnalyticsSlice = createSlice({
  name: 'quizAnalytics',
  initialState: {
    quizAnalytics: {},
    isLoading: false,
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchQuizAnalytics.pending, state => {
      state.quizAnalytics = [];
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchQuizAnalytics.fulfilled, (state, action) => {
      state.quizAnalytics = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchQuizAnalytics.rejected, (state, action) => {
      state.quizAnalytics = [];
      state.isLoading = false;
      state.error = action.payload.error;
    });
  }
});

export default quizAnalyticsSlice.reducer;
