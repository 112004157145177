import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const DataCollection = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="data-collection" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Data Collection</h5>
      </Card.Header>
      <Card.Body>
        <h6>Types of Data Collected</h6>
        <p className="fs-0 mb-0 fw-semi-bold">
          We collect various types of data to provide and improve our services:
        </p>
        <ul>
          <li>
            <strong>Discord Information:</strong> Includes your Discord ID and
            associated profile details needed for account creation and
            management.
          </li>
          <li>
            <strong>Account Information:</strong> Details related to your
            account, such as username, profile settings, and preferences.
          </li>
          <li>
            <strong>Usage Data:</strong> Information on how you use our
            services, including interaction with features, time spent, and
            content accessed.
          </li>
          <li>
            <strong>Participant Data:</strong> Information collected from users
            who participate in quizzes. This data is used to execute bot logic
            and provide analytics to quiz creators.
          </li>
        </ul>
        <hr className="my-4" />
        <h6>How We Collect Data</h6>
        <p className="mb-0 ps-3">Data is collected in the following ways:</p>
        <ul>
          <li>
            <strong>Direct Collection:</strong> When you provide information
            directly to us by connecting your Discord account.
          </li>
          <li>
            <strong>Automated Collection:</strong> Through cookies, web beacons,
            and other tracking technologies as you interact with our services.
          </li>
          <li>
            <strong>Bot Interaction:</strong> Data generated through quiz
            participation, which is used for bot functionality and analytical
            purposes.
          </li>
        </ul>
        <hr className="my-4" />
        <h6>Why We Collect Data</h6>
        <p className="mb-0 ps-3">The data we collect helps us:</p>
        <ul>
          <li>Provide, operate, and maintain our services.</li>
          <li>Improve, personalize, and expand our services.</li>
          <li>Understand and analyze how you use our services.</li>
          <li>Develop new products, services, and features.</li>
          <li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, updates, and promotional
            purposes.
          </li>
          <li>Process your transactions and manage your orders.</li>
        </ul>
        <hr className="my-4" />
        <h6>Web3 Data</h6>
        <p className="mb-0 ps-3">
          Please note that we do not collect or store any data related to Web3.
        </p>
      </Card.Body>
    </Card>
  );
});

export default DataCollection;
