import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to fetch users with pagination
export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async ({ token, quizId, limit = 10, page = 1 }, thunkAPI) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/${quizId}?limit=${limit}&page=${page}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Async thunk to search users
export const searchUsers = createAsyncThunk(
  'users/searchUsers',
  async ({ token, quizId, limit, page, query }, thunkAPI) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/search/${query}/${quizId}/${limit}/${page}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    isLoading: false,
    isSearching: false,
    searchNotFound: false,
    error: null,
    pagination: {
      currentPage: 1,
      totalPages: 0,
      limit: 10
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUsers.pending, state => {
        state.users = [];
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload.users || [];
        state.pagination = action.payload.pagination || {};
        state.isLoading = false;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.users = [];
        state.isLoading = false;
        state.error = action.payload.error;
      })
      .addCase(searchUsers.pending, state => {
        state.users = [];
        state.isLoading = true;
        state.isSearching = true;
        state.error = null;
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        state.users = action.payload.users || [];
        state.isLoading = false;
        state.isSearching = false;
        state.searchNotFound = state.users.length === 0;
      })
      .addCase(searchUsers.rejected, (state, action) => {
        state.users = [];
        state.isLoading = false;
        state.isSearching = false;
        state.error = action.payload.error;
      });
  }
});

export default usersSlice.reducer;
