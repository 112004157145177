import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Dropdown, Col, Spinner } from 'react-bootstrap';

import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import CardDropdown from 'components/common/CardDropdown';
import UserAttemptsModal from './userAttemptsModal';

import { fetchUsers, searchUsers } from 'store/usersSlice';
import { useParams } from 'react-router-dom';

const UsersTable = () => {
  const dispatch = useDispatch();
  const usersData = useSelector(state => state.users.users);
  const token = useSelector(state => state.auth.token);
  const { quizId } = useParams();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [attemptsModal, setAttemptsModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const isLoadingUsers = useSelector(state => state.users.isLoading);
  const isSearching = useSelector(state => state.users.isSearching);
  const searchNotFound = useSelector(state => state.searchNotFound);
  const totalMembers = useSelector(
    state => state.analytics.quizAnalytics.totalMembers
  );
  const [searchQuery, setSearchQuery] = useState('');

  const totalPages = Math.ceil(totalMembers / itemsPerPage);
  console.log('totalPages: ', totalPages);
  const handleAttemptClick = userId => {
    setAttemptsModal(true);
    setSelectedUserId(userId);
  };

  const handleClose = () => {
    setAttemptsModal(false);
  };
  console.log('isSearching: ', isSearching);
  useEffect(() => {
    if (searchQuery) {
      dispatch(
        searchUsers({
          token,
          quizId,
          limit: itemsPerPage,
          page: currentPage,
          query: searchQuery
        })
      );
    } else {
      dispatch(
        fetchUsers({ token, quizId, limit: itemsPerPage, page: currentPage })
      );
    }
  }, [dispatch, token, quizId, currentPage, itemsPerPage, searchQuery]);

  useEffect(() => {
    dispatch(
      fetchUsers({ token, quizId, limit: itemsPerPage, page: currentPage })
    );
  }, [dispatch, token, quizId, currentPage, itemsPerPage]);

  const columns = [
    {
      accessor: 'username',
      Header: 'User',
      Cell: rowData => (
        <div className="d-flex align-items-center">
          <Avatar src={rowData.row.original.avatar} className="me-2" />
          <p className="mb-0">{rowData.row.original.username}</p>
        </div>
      )
    },
    {
      accessor: 'discordId', // Assuming the Discord ID is stored under this key in your data
      Header: 'Discord ID',
      Cell: rowData => <p>{rowData.row.original.discordId}</p>
    },
    {
      accessor: 'numberOfAttempts',
      Header: 'Attempts Passed',
      Cell: rowData => <p>{rowData.row.original.numberOfAttempts}</p>
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const user = rowData.row.original;
        const userId = rowData.row.original.userId;
        console.log('user: ', user);
        console.log('current page: ', currentPage);
        console.log('page size: ', itemsPerPage);
        return (
          <>
            <CardDropdown>
              <Dropdown.Item
                className="elevated-dropdown"
                key={userId}
                onClick={() => handleAttemptClick(userId)}
              >
                Attempts
              </Dropdown.Item>
              <Dropdown.Divider as="div" />
              {/* Add a click handler for delete functionality if needed */}
              <Dropdown.Item href="#!" className="text-danger" disabled>
                Block
              </Dropdown.Item>
            </CardDropdown>
            {selectedUserId === userId && (
              <UserAttemptsModal
                attemptsModalshow={attemptsModal}
                handleClose={handleClose}
                attempts={user.attempts}
              />
            )}
          </>
        );
      }
    }
  ];

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={usersData}
        sortable
        pagination
        perPage={itemsPerPage}
        className="pt-3"
        pageCount={totalPages}
      >
        {!totalPages || !token ? (
          <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Flex>
        ) : (
          <>
            <Flex justifyContent="center" className="p-2 mb-2">
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table setSearchQuery={setSearchQuery} />
              </Col>
            </Flex>
            <Card className="mb-3">
              {isSearching || isLoadingUsers ? (
                <Flex justifyContent="center" className="p-2 mb-2">
                  <Spinner animation="border" role="status"></Spinner>
                </Flex>
              ) : searchNotFound || usersData.length === 0 ? (
                <Flex justifyContent="center" className="p-2 mb-2">
                  <span>Nothing was found.</span>
                </Flex>
              ) : isLoadingUsers ? (
                <Flex justifyContent="center" className="p-2 mb-2">
                  <Spinner animation="border" role="status"></Spinner>
                </Flex>
              ) : (
                <Card.Body className="p-0">
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap hover-actions-trigger btn-reveal-trigger hover-bg-100"
                    tableProps={{
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                  />
                </Card.Body>
              )}
              <Card.Footer>
                <AdvanceTableFooter
                  rowCount={totalMembers}
                  pageSize={itemsPerPage}
                  page={usersData}
                  setPageSize={setItemsPerPage}
                  pageIndex={currentPage}
                  canPreviousPage={currentPage > 1}
                  canNextPage={currentPage < totalPages}
                  nextPage={() => setCurrentPage(prev => prev + 1)}
                  previousPage={() => setCurrentPage(prev => prev - 1)}
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </Card.Footer>
            </Card>
          </>
        )}
      </AdvanceTableWrapper>
    </>
  );
};

export default UsersTable;
