import React, { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row, ListGroup, Spinner } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { fetchQuizAnalytics } from '../../../../store/analyticsSlice';

const formatDate = dateString => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = String(date.getFullYear()).slice(-2); // Get the last 2 digits of the year
  return `${day} ${month} ${year}`;
};

const QuizDetailsOverview = ({ quiz }) => {
  const dispatch = useDispatch();
  const quizId = quiz ? quiz._id : null;
  // Fetch analytics data
  const token = useSelector(state => state.auth.token);
  useEffect(() => {
    dispatch(fetchQuizAnalytics({ token, quizId }));
  }, [dispatch, token, quizId]);

  const analytics = useSelector(state => state.analytics.quizAnalytics);

  // Calculate total members and attempts
  const totalMembers = analytics.totalMembers;
  const totalAttempts = analytics.totalAttempts;

  const renderStatusBadge = () => {
    switch (quiz.status) {
      case 'active':
        return (
          <SoftBadge bg="success" className="me-2">
            Active
          </SoftBadge>
        );
      case 'finished':
        return (
          <SoftBadge bg="danger" className="me-2">
            Finished
          </SoftBadge>
        );
      case 'scheduled':
        return (
          <SoftBadge bg="dark" className="me-2">
            Scheduled
          </SoftBadge>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {!quizId ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <>
          <Card className="mb-3">
            <Card.Body>
              <Row className="g-3 align-items-center">
                <Col lg={6} className="mb-4 mb-lg-0">
                  <p className="mb-0 fw-medium text-400">
                    {renderStatusBadge()}
                  </p>
                  <h2 className="fw-bold">{quiz.name}</h2>
                  <p className="fw-semi-bold fs--1">
                    Command: /{quiz.commandName}
                  </p>
                  <p className="mb-4 fw-medium text-400">{quiz.description}</p>
                  <h6 className="mb-0 fs--2 text-500">
                    {formatDate(quiz.startDate)}–{formatDate(quiz.endDate)}
                  </h6>
                </Col>
                <Col lg={6}>
                  <ListGroup>
                    <ListGroup.Item>
                      <Flex justifyContent="between" alignItems="center">
                        Questions
                        <SoftBadge pill bg="primary" className="me-2">
                          {quiz.questionsNumberPerAttempt}
                        </SoftBadge>
                      </Flex>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Flex justifyContent="between" alignItems="center">
                        Passing score
                        <SoftBadge pill bg="primary" className="me-2">
                          {quiz.numberOfCorrectAnswers}
                        </SoftBadge>
                      </Flex>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Flex justifyContent="between" alignItems="center">
                        Time per question
                        <SoftBadge pill bg="primary" className="me-2">
                          {quiz.timePerQuestion} sec
                        </SoftBadge>
                      </Flex>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Flex justifyContent="between" alignItems="center">
                        Timeout
                        <SoftBadge pill bg="primary" className="me-2">
                          {quiz.timeout} min
                        </SoftBadge>
                      </Flex>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Flex justifyContent="between" alignItems="center">
                        Total attempts
                        <SoftBadge pill bg="primary" className="me-2">
                          {quiz.attemptsLimit}
                        </SoftBadge>
                      </Flex>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Flex justifyContent="between" alignItems="center">
                        Attempts per member
                        <SoftBadge pill bg="primary" className="me-2">
                          {quiz.attemptsNumberPerUser}
                        </SoftBadge>
                      </Flex>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Flex justifyContent="between" alignItems="center">
                        Members limit
                        <SoftBadge pill bg="primary" className="me-2">
                          {quiz.usersLimit}
                        </SoftBadge>
                      </Flex>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
              <hr className="text-secondary text-opacity-50" />
              {!analytics ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <>
                  <ul className="list-unstyled d-flex flex-wrap gap-3 fs--1 fw-semi-bold text-300 mt-3 mb-0">
                    <li>
                      <FontAwesomeIcon icon="graduation-cap" className="me-1" />
                      {totalMembers} Members {/* Display total members */}
                    </li>
                    <li>
                      <FontAwesomeIcon icon="user-graduate" className="me-1" />
                      {totalAttempts} Attempts {/* Display total attempts */}
                    </li>
                  </ul>
                </>
              )}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};
QuizDetailsOverview.propTypes = {
  quiz: PropTypes.func.isRequired
};

export default QuizDetailsOverview;
