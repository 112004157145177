import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';

const Rights = forwardRef((props, ref) => {
  return (
    <Card className="mb-3" id="rights" ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Rights & Responsibilities</h5>
      </Card.Header>
      <Card.Body>
        <h6>Intellectual Property Rights</h6>
        <p className="mb-0 ps-3">
          All rights, including intellectual property rights, in and to the
          EzQuize platform are owned by EzQuizeDAO. Unauthorized use of our
          trademarks or other intellectual property is strictly prohibited.
        </p>
        <hr className="my-4" />
        <h6>User Content</h6>
        <p className="mb-0 ps-3">
          You retain ownership of any content you upload or post on EzQuize. By
          using our platform, you grant us a license to use, display, and
          distribute your content to facilitate the services we provide.
        </p>
        <hr className="my-4" />
        <h6>Privacy Policy</h6>
        <p className="mb-0 ps-3">
          Your privacy is important to us. Our Privacy Policy is incorporated
          into these Terms and outlines how we handle your personal information.
        </p>
        <hr className="my-4" />
        <h6>Waiver</h6>
        <p className="mb-0 ps-3">
          Failure to enforce any provision of these Terms does not constitute a
          waiver of that provision.
        </p>
        <hr className="my-4" />
        <h6>Survival</h6>
        <p className="mb-0 ps-3">
          Certain provisions of these Terms will continue to be effective even
          after termination or expiration of your account, including but not
          limited to the sections on indemnity, limitation of liability, and
          governing law.
        </p>
        <hr className="my-4" />
        <h6>Severability</h6>
        <p className="mb-0 ps-3">
          If any provision of these Terms is found to be unenforceable, the
          remaining provisions will continue in full force and effect.
        </p>
        <hr className="my-4" />
        <h6>Entire Agreement</h6>
        <p className="mb-0 ps-3">
          These Terms, along with any additional terms or policies referenced
          herein, represent the entire agreement between you and EzQuizeDAO.
          regarding your use of our platform.
        </p>
        <hr className="my-4" />
        <h6>Governing Law</h6>
        <p className="mb-0 ps-3">
          These Terms are governed by the laws of the United States. Any
          disputes arising out of these Terms will be resolved in the
          jurisdiction of the United States.
        </p>
        <hr className="my-4" />
        <h6>Consumer Rights</h6>
        <p className="mb-0 ps-3">
          If you are a consumer, you have certain rights under applicable
          consumer protection laws. These Terms do not affect those rights.
        </p>
        <p className="pt-2">Last update: 20 Aug 2024</p>
      </Card.Body>
    </Card>
  );
});

export default Rights;
