import React, { useState } from 'react';
import {
  Modal,
  InputGroup,
  FormControl,
  Button,
  DropdownButton,
  Dropdown,
  Form
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addQuiz } from 'store/quizSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const QuizCreationModal = ({ showModal, handleClose, servers }) => {
  const token = useSelector(state => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [commandName, setCommandName] = useState('');
  const [roleName, setRoleName] = useState('');
  const [roleId, setRoleId] = useState('');
  const [accessRoleId, setAccessRoleId] = useState('');
  //const [accessRole, setAccessRole] = useState('');
  const [serverId, setServerId] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [accessRole, setAccessRoleName] = useState('');
  const [isAccessRoleActive, setIsAccessRoleActive] = useState(false);

  const userId = useSelector(state => state.auth.user); // Get the user object
  const selectedServer = servers.find(server => server.serverId === serverId);
  const selectedRole = selectedServer
    ? selectedServer.roles.find(role => role.id === roleId)
    : null;
  const selectedAccessRole = selectedServer
    ? selectedServer.roles.find(r => r.id === accessRoleId)
    : null;

  const handleSave = () => {
    const quizData = {
      name,
      description,
      commandName,
      roleName,
      accessRole,
      numberOfCorrectAnswers: '8',
      timeout: '60',
      serverId,
      timePerQuestion: '45',
      attemptsNumberPerUser: '100',
      questionsNumberPerAttempt: '10',
      usersLimit: '10000',
      attemptsLimit: '1000000',
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      status: 'scheduled',
      createdBy: userId
    };
    console.log('quizData: ', quizData);
    dispatch(addQuiz({ token, quizData })) // <-- Dispatch the addQuiz action
      .then(action => {
        if (action.payload && action.payload._id) {
          // Check if the payload contains the quiz ID
          navigate(`/quiz/${action.payload._id}`);
        }
        handleClose();
      })
      .catch(error => {
        console.error('Failed to add quiz:', error);
      });
  };

  const handleInviteBot = () => {
    const url = `https://discord.com/api/oauth2/authorize?client_id=1071731969819430922&permissions=8&scope=bot`;
    window.open(url, '_blank');
  };

  const isFormValid = () => {
    return name && description && commandName;
  };
  console.log('servers: ', servers);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      servers={servers}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Quiz</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <InputGroup hasValidation className="mb-3">
            <InputGroup.Text>Name</InputGroup.Text>
            <FormControl
              placeholder="Quiz Name"
              value={name}
              onChange={e => setName(e.target.value)}
              isInvalid={isConsentChecked && !name}
            />
          </InputGroup>

          <InputGroup hasValidation className="mb-3">
            <InputGroup.Text>Description</InputGroup.Text>
            <FormControl
              placeholder="Description"
              value={description}
              onChange={e => setDescription(e.target.value)}
              isInvalid={isConsentChecked && !description}
            />
          </InputGroup>

          <InputGroup hasValidation className="mb-3">
            <InputGroup.Text>Command Name</InputGroup.Text>
            <FormControl
              placeholder="Command Name"
              value={commandName}
              onChange={e => setCommandName(e.target.value)}
              isInvalid={isConsentChecked && !commandName}
            />
          </InputGroup>

          <div className="mt-4 mb-2 navbar-vertical-label-wrapper row">
            <div className="navbar-vertical-label col-auto">Discord</div>
            <div className="ps-0 col">
              <hr className="mb-0 navbar-vertical-divider" />
            </div>
          </div>

          <InputGroup className="mb-3">
            <DropdownButton
              variant="outline-secondary"
              title={serverId ? 'Server' : 'Select Server'}
              id="server-dropdown"
            >
              {servers.map(server => (
                <Dropdown.Item
                  key={server.serverId}
                  onClick={() => setServerId(server.serverId)}
                >
                  {server.serverName}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <FormControl
              aria-label="Server ID"
              value={selectedServer ? selectedServer.serverName : ''}
              readOnly
            />
            <Button variant="outline-danger" onClick={handleInviteBot}>
              Invite BOT
            </Button>
          </InputGroup>

          {serverId && (
            <InputGroup className="mb-3">
              <DropdownButton
                variant="outline-secondary"
                title={roleName ? 'Role Grant' : 'Select Role'}
                id="role-dropdown"
              >
                {selectedServer.roles.map(role => (
                  <Dropdown.Item
                    key={role.id}
                    onClick={() => {
                      setRoleName(role.name);
                      setRoleId(role.id);
                    }}
                  >
                    {role.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <FormControl
                aria-label="Role Name"
                value={selectedRole ? selectedRole.name : ''}
                readOnly
              />
            </InputGroup>
          )}
          {serverId && (
            <Form.Check
              type="switch"
              id="accessRoleSwitch"
              label="Enable Access Role"
              onChange={e => {
                const isChecked = e.target.checked;
                setIsAccessRoleActive(isChecked);
                if (!isChecked) {
                  setAccessRoleName('');
                  setAccessRoleId('');
                }
              }}
            />
          )}
          {serverId && (
            <InputGroup className="mb-3">
              <DropdownButton
                variant="outline-secondary"
                title={accessRole ? 'Access Role' : 'Select Access Role'}
                id="access-role-dropdown"
                disabled={!isAccessRoleActive}
              >
                {selectedServer.roles.map(role => (
                  <Dropdown.Item
                    key={role.id}
                    onClick={() => {
                      setAccessRoleId(role.id);
                      setAccessRoleName(role.name);
                    }}
                  >
                    {role.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <FormControl
                aria-label="Access Role Name"
                value={
                  selectedAccessRole ? selectedAccessRole.name : accessRole
                }
                readOnly
                disabled={!isAccessRoleActive}
              />
            </InputGroup>
          )}
          <div className="mt-4 mb-2 navbar-vertical-label-wrapper row">
            <div className="navbar-vertical-label col-auto">Scheduling</div>
            <div className="ps-0 col">
              <hr className="mb-0 navbar-vertical-divider" />
            </div>
          </div>
          <InputGroup className="mb-3">
            <InputGroup.Text>
              {startDate ? 'Start' : 'Select Date'}
            </InputGroup.Text>
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              className="form-control"
              placeholderText="DD-MM-YYYY h:mm aa"
              timeIntervals={5}
              dateFormat="dd-MM-yyyy h:mm aa"
              showTimeSelect
              fixedHeight
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Text>{endDate ? 'End' : 'Select Date'}</InputGroup.Text>
            <DatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              className="form-control"
              placeholderText="DD-MM-YYYY h:mm aa"
              timeIntervals={5}
              dateFormat="dd-MM-yyyy h:mm aa"
              showTimeSelect
              fixedHeight
              minDate={startDate}
            />
          </InputGroup>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Form.Check
          type="checkbox"
          id="defaultCheckbox"
          label="Consent to the terms of use of the service"
          onChange={e => setIsConsentChecked(e.target.checked)}
        />
        <Button
          variant="success"
          onClick={handleSave}
          disabled={!isConsentChecked || !isFormValid()}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

QuizCreationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  servers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    })
  ).isRequired
};

export default QuizCreationModal;
