import React, { useEffect } from 'react';
import { Modal, InputGroup, FormControl, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchQuestionDetails,
  editQuestionDetails
} from '../../../../../store/questionDetails';
import PropTypes from 'prop-types';

const QuestionModal = ({ editModalShow, questionId, handleClose }) => {
  const dispatch = useDispatch();
  const questionDetails =
    useSelector(state => state.questionDetails.questionDetails) || {};
  const token = useSelector(state => state.auth.token);

  useEffect(() => {
    if (questionId) {
      dispatch(fetchQuestionDetails({ token, questionId }));
    }
  }, [dispatch, token, questionId]);

  const addAnswerOption = () => {
    if (questionDetails.answers && questionDetails.answers.length < 4) {
      const newAnswers = [
        ...questionDetails.answers,
        { answer: 'test', isCorrect: false }
      ];
      dispatch(
        editQuestionDetails({
          token,
          questionId,
          updatedData: { ...questionDetails, answers: newAnswers }
        })
      );
    }
  };

  const removeAnswerOption = () => {
    if (questionDetails.answers && questionDetails.answers.length > 2) {
      const newAnswers = questionDetails.answers.slice(0, -1);
      dispatch(
        editQuestionDetails({
          token,
          questionId,
          updatedData: { ...questionDetails, answers: newAnswers }
        })
      );
    }
  };

  const handleSave = () => {
    dispatch(
      editQuestionDetails({ token, questionId, updatedData: questionDetails })
    );
    handleClose();
  };

  console.log('string 69: ', editModalShow);
  return (
    <>
      <Modal
        show={editModalShow}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Edit Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {/* Question Field */}
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">?</InputGroup.Text>
              <FormControl
                placeholder="Question"
                aria-label="Question"
                aria-describedby="basic-addon1"
                value={questionDetails.question || ''}
                onChange={e => {
                  const updatedQuestion = {
                    ...questionDetails,
                    question: e.target.value
                  };
                  dispatch(
                    editQuestionDetails({
                      token,
                      questionId,
                      updatedData: updatedQuestion
                    })
                  );
                }}
              />
            </InputGroup>

            {/* Answer Fields */}
            {questionDetails.answers &&
              questionDetails.answers.map((answer, index) => (
                <InputGroup key={index} className="mb-3">
                  <InputGroup.Text>
                    {String.fromCharCode(65 + index)}
                  </InputGroup.Text>
                  <InputGroup.Radio
                    name="answer"
                    aria-label={`Radio button for answer ${index}`}
                    checked={answer.isCorrect}
                    onChange={() => {
                      const updatedAnswers = questionDetails.answers.map(
                        (ans, idx) =>
                          idx === index
                            ? { ...ans, isCorrect: true }
                            : { ...ans, isCorrect: false }
                      );
                      dispatch(
                        editQuestionDetails({
                          token,
                          questionId,
                          updatedData: {
                            ...questionDetails,
                            answers: updatedAnswers
                          }
                        })
                      );
                    }}
                  />
                  <FormControl
                    placeholder={`Answer ${String.fromCharCode(65 + index)}`}
                    aria-label={`Answer ${String.fromCharCode(65 + index)}`}
                    value={answer.answer}
                    onChange={e => {
                      const updatedAnswers = [...questionDetails.answers];
                      updatedAnswers[index] = {
                        ...updatedAnswers[index],
                        answer: e.target.value
                      };
                      dispatch(
                        editQuestionDetails({
                          token,
                          questionId,
                          updatedData: {
                            ...questionDetails,
                            answers: updatedAnswers
                          }
                        })
                      );
                    }}
                  />
                  {(index === 2 || index === 3) && (
                    <Button variant="danger" onClick={removeAnswerOption}>
                      Delete
                    </Button>
                  )}
                </InputGroup>
              ))}

            {questionDetails.answers && questionDetails.answers.length < 4 && (
              <Button variant="primary" onClick={addAnswerOption}>
                Add Answer Option
              </Button>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

QuestionModal.propTypes = {
  questionId: PropTypes.string.isRequired,
  editModalShow: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default QuestionModal;
